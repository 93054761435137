
<nav class="navLogin">
  <div>
    <ul>
      &nbsp;
    </ul>
  </div>
</nav>
<div class="container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="flex-container ">
    <div *ngIf="reactivationFailed && !emailValidationFailed">
      <p>Resend activation mail failed due to some technical issue. Please try again.</p>
      <span class="signintab">
        <a href="#login.html"> Sign in</a>
      </span>
    </div>
    <div *ngIf="emailValidationFailed">
      <p>Invalid email address! Failed to resend activation mail.</p>
      <span class="signintab">
        <a href="#login.html"> Sign in</a>
      </span>
    </div>
    <div class="loginSection  " *ngIf="!reactivationSuccess && !reactivationFailed">
      <h1 class="userIcon">
        <i class="fa fa-user" aria-hidden="true"></i>
      </h1>
      <div class="textbox">
        <i class="fa fa-user" aria-hidden="true">
        </i>
        <input type="email" [(ngModel)]="user.userID" placeholder="Email" name="LoginEmail" formControlName="LoginEmail">
        <div *ngIf="submitted && loginForm.controls.LoginEmail.errors" class="invalid-feedback2">
          <div *ngIf="loginForm.controls.LoginEmail.errors.required">Email ID is required</div>

        </div>
      </div>
      <div class="textbox">
        <i class="fa fa-key" aria-hidden="true"></i>
        <input type="password" [(ngModel)]="user.password" placeholder="Password" name="loginPassword" formControlName="loginPassword">
        <div *ngIf="submitted && loginForm.controls.loginPassword.errors" class="invalid-feedback2">
          <div *ngIf="loginForm.controls.loginPassword.errors.required">Password is required</div>
        </div>
      </div>

      <a class="forgotPassword" routerLink="/forgotpassword" [class.disabled]="isActive ? true : null">
        Forgot your Password?
      </a>

      <button [disabled]="loading" class="buttonlogin">
        <span *ngIf="loading"></span>
        Login
      </button>


      <button type="button" class="btn btn-outline-primary" routerLink="/register" routerLinkActive="active">Create Account</button>
      <div *ngIf="isInActiveUser" class="aLink">
        <div class="invalid-feedback3"> User is InActive.</div>
        <a class="activationLink" role="button" (click)="ResendActivationLink()" style="color: #dc3545;">Resend Activation Link </a>
        <!--Squish#44 - Hyperlink text Color Changed-->
      </div>
      <div *ngIf="!loginSuccess" class="invalid-feedback2">
        <p>Invalid User Credentials. Please try again.</p>
      </div>
      <div *ngIf="accountLocked" class="invalid-feedback2">
        <p>User Account Locked. Please try again after 2 hours.</p>
      </div>
    </div>

  </form>
</div>


<div *ngIf="reactivationSuccess">
  <p>User activation mail has been resend to user. Please confirm the email received to activate the account.</p>
  <span class="signintab">
    <a href="#login.html"> Sign in</a>
  </span>
</div>


  <ng-template #termsupdated let-modal>
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Term of Use and Privacy Statement Update</p>
      <p>
        Welcome to {{CompanyName}}! Please be advised that we've updated our Term of Use and Privacy Statement on {{versionUpdateDate | date}}.
        By clicking Continue, you are confirming that you have read and agree to our <a [routerLink]="" (click)="modal.close('Close click');openTermsAndConditions(terms)"
                                                                                        style="color:dodgerblue">Terms of Use and Privacy Statement</a>
      </p>

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click');UpdateTermsVersion()">Continue</button>
    </div>
  </ng-template>

  <ng-template #terms let-modal>
    <div class="modal-header">
      Insured Portal
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-lg">
      <iframe *ngIf="iframeTerms" id="pdfViewer1" [src]="iframeTerms" style="width: 100%; height: 400px;" zindex="100"></iframe>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>

  </ng-template>
  <ng-template #errorMsg let-modal>
    <div class="modal-header">

      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- class="modal-body modal-lg" -->
    <div class="modal-body">
      Error occurred in updating the terms version.Please try again.

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>

