import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Policy} from '../_models/policy.model';
import {environment} from '../../environments/environment'
import { Router } from '@angular/router';
import { Agent } from '../_models/agent.model';
import { Invoice } from '../_models/invoice.model';
import { Payment } from '../_models/payment.model';
import { Address } from '../_models/address.model';
import { Coverage } from '../_models/coverage.model';
import { Endorsement } from '../_models/endorsement.model';
import { Underwriting } from '../_models/underwriting.model';
import { Mortgagee } from '../_models/mortgagee.model';
import { Claim } from '../_models/claim.model';
import { AdditionalInsured} from '../_models/additionalInsured.model';
import { IRDocument } from '../_models/irdocument.model';


  @Injectable({
    providedIn: 'root'
  })
  
  export class PolicyService {
     
     baseUrl = environment.apiUrl;
  
    constructor(private http: HttpClient,private router: Router) { }
  
    

    GetActivePolicyList(companyId: string,subCompanyId: string ,nameID: string) {
      console.log('GetActivePolicyList');
      var token = localStorage.getItem('token');
    const httpparams = new HttpParams()
    .set('companyId', companyId)
    .set('subCompanyId', subCompanyId)
    .set('nameID', nameID);
    
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),params:httpparams }; 
    return this.http.get<Policy[]>(this.baseUrl + 'Policy/GetActivePolicyList/',httpOptions);  
      } 
   
    GetPolicyData(policy: Policy): Observable<Policy> { 
        
      console.log('GetPolicyData');
      //console.log(policy);
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
        return this.http.post<Policy>(this.baseUrl + 'Policy/GetPolicyData/',  
          policy, httpOptions);  
      }
 
      GetAgentData(policy: Policy): Observable<Agent> { 
        
        console.log('GetAgentData');
        //console.log(policy);
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
          return this.http.post<Agent>(this.baseUrl + 'Policy/GetAgentInfo/',  
            policy, httpOptions);  
        }
   
        GetInvoices(policy: Policy): Observable<Invoice[]> { 
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
          return this.http.post<Invoice[]>(this.baseUrl + 'Policy/GetInvoices/' ,
          policy,httpOptions);  
            
            } 
  
        GetPaymentHistory(policy: Policy): Observable<Payment[]> { 
              const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
              return this.http.post<Payment[]>(this.baseUrl + 'Policy/GetPaymentHistory/' ,
              policy,httpOptions);  
                
                } 

      GetBillingAddress(policy: Policy): Observable<Address> { 
                  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                  return this.http.post<Address>(this.baseUrl + 'Policy/GetBillingAddress/' ,
                  policy,httpOptions);  
                    
                    } 
     
    GetLocationAddress(policy: Policy): Observable<Address> { 
                      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                      return this.http.post<Address>(this.baseUrl + 'Policy/GetInsuredLocation/' ,
                      policy,httpOptions);  
                        
                        } 
    GetCoverages(policy: Policy): Observable<Coverage[]> { 
                      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                      return this.http.post<Coverage[]>(this.baseUrl + 'Policy/GetCoverageInfo/' ,
                      policy,httpOptions);  
                        
                        } 
    GetEndorsements(policy: Policy): Observable<Endorsement[]> { 
                      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                      return this.http.post<Endorsement[]>(this.baseUrl + 'Policy/GetEndorsementInfo/' ,
                      policy,httpOptions);  
                        
                        } 

    GetWHDeductible(policy: Policy): Observable<Underwriting> { 
                          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                          return this.http.post<Underwriting>(this.baseUrl + 'Policy/GetWHDeductible/' ,
                          policy,httpOptions);  
                            
                            } 
    
    GetMortgagees(policy: Policy): Observable<Mortgagee[]> { 
                              const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                              return this.http.post<Mortgagee[]>(this.baseUrl + 'Policy/GetMortgageeList/' ,
                              policy,httpOptions);  
                                
                               } 
    GetClaims(policy: Policy): Observable<Claim[]> { 
                                const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                                return this.http.post<Claim[]>(this.baseUrl + 'Policy/GetClaims/' ,
                                policy,httpOptions);  
                                  
                                  } 
     GetAddInsured(policy: Policy): Observable<AdditionalInsured[]> { 
                                    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
                                    return this.http.post<AdditionalInsured[]>(this.baseUrl + 'Policy/GetAdditionalInsured/' ,
                                    policy,httpOptions); 
      }

     
    GetInvoiceDocuments(companyId: string, subCompanyId: string, policyNo: string, userID: string, insuredNameId: string, userName: string, insuredUserName: string, isCsr: any,pmrStatus:string) :Observable<Blob> {
     
     // var token = localStorage.getItem('token');
    const httpparams = new HttpParams()
    .set('companyId', companyId)
      .set('subCompanyId', subCompanyId)
      .set('trkUserID', userID)
      .set('trkUserName', userName)
      .set('trkInsuredNameId', insuredNameId)
      .set('trkInsuredUserName', insuredUserName)
      .set('trkIsCsr', isCsr)
      .set('pmrStatus', pmrStatus)

    .set('policyNo', policyNo);
    
    const httpOptions = { responseType: 'blob' as 'json', headers: new HttpHeaders({ 'Content-Type': 'application/json' }),params:httpparams }; 
    return this.http.get<Blob>(this.baseUrl + 'ImageRight/GetInvoiceDocuments/',httpOptions);  
      } 

      GetPolicyDocuments(companyId: string,subCompanyId: string ,policyNo: string, userID: string, insuredNameId:string,userName:string, insuredUserName:string, isCsr:any) :Observable<Blob> {
   
       // var token = localStorage.getItem('token');
      const httpparams = new HttpParams()
      .set('companyId', companyId)
      .set('subCompanyId', subCompanyId)
        .set('trkUserID', userID)
        .set('trkUserName', userName)
        .set('trkInsuredNameId', insuredNameId)
        .set('trkInsuredUserName', insuredUserName)
        .set('trkIsCsr', isCsr)
      .set('policyNo', policyNo);
      
      const httpOptions = { responseType: 'blob' as 'json', headers: new HttpHeaders({ 'Content-Type': 'application/json' }),params:httpparams }; 
      return this.http.get<Blob>(this.baseUrl + 'ImageRight/GetPolicyDocuments/',httpOptions);  
        } 


        
      // UploadDocuments(document: InspectionDocument) :Observable<any> {
   
      //   const formData = new FormData();
      //    //formData.append('inspectionDocument',document.documentData );
      //   return this.http.post(this.baseUrl + 'ImageRight/UploadInspectionDocuments/', formData);
      //    } 
}
