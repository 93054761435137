<div *ngIf="companyID == 'MPI'" class="LogoImage">
    <!-- <a  [routerLink]="[isLoggedIn ? '/dashboard':'']" ></a> -->
    <img src="assets\images\mpiualogo.png" class="img-responsive">
</div>
<div *ngIf="companyID == 'RIJ'" class="LogoImage">
    <!-- <a  routerLink="/dashboard"></a>  -->
    <img  src="assets\images\rijralogo.png" class="img-responsive">
 </div>
<div class="warningMessage " *ngIf="url=='apps-uat.mpiuatest.com'">  <!-- apps-uat.mpiuatest.com -->
<p>You are currently in a test environment. Any transactions entered will not be accepted or processed. Please contact Customer Service!</p>
</div>


