import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { TestComponent } from './test/test.component';
import { ValidateEqualModule } from 'ng-validate-equal';
import { UseractivationComponent } from './useractivation/useractivation.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import {EditProfileComponent} from './editProfile/editprofile.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth.interceptor';
import { FormsModule } from '@angular/forms';
import { MinusToParenthesisPipe } from './_helpers/minus-to-parenthesis.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CsrLoginComponent } from './csr/csrlogin.component';
import { InspectionComponent } from './inspection/inspection.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { DocumentsComponent } from './documents/documents.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    TestComponent,
    UseractivationComponent,
    ForgotpasswordComponent,
    HeaderComponent,
    FooterComponent,
      DashboardComponent,
      EditProfileComponent,
      MinusToParenthesisPipe,
      ChangePasswordComponent,
      CsrLoginComponent,
      InspectionComponent,
      NavigationBarComponent,
      DocumentsComponent     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,HttpClientModule,ValidateEqualModule,FormsModule, BrowserAnimationsModule,
    NgbModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
