import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Inspection } from "../_models/inspection.model";
import { IRDocument } from "../_models/irdocument.model";


@Injectable({
    providedIn: 'root'
  })
export class InspectionService{
    baseUrl = environment.apiUrl;
    blob: Blob;
    constructor(private http: HttpClient,private router: Router) { }

    GetInspectionContactDetails(inspection: Inspection): Observable<Inspection>{
        console.log('GetInspectionContactDetails');
      console.log(inspection);
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
        return this.http.post<Inspection>(this.baseUrl + 'Inspection/GetInspectionContactDetails/',  
          inspection, httpOptions); 
    }
    SaveInspectionContactDetails(inspection:Inspection):Observable<boolean>{
            const httpOptions={headers:new HttpHeaders({'Content-Type':'application/json'})};
            return this.http.post<boolean>(this.baseUrl + 'Inspection/SaveInspectionContactDetails/',  
    inspection, httpOptions);  

    }
    GetInspectionStatusDetails(inspection:Inspection):Observable<Inspection>{
            console.log('GetInspectionStatusDetails');
            //console.log(inspection);
            const httpOptions={headers:new HttpHeaders({'Content-Type':'application/json'})};
            return this.http.post<Inspection>(this.baseUrl + 'Inspection/GetInspectionStatusDetails/',
            inspection,httpOptions);
    }
    GetInspectionDocuments(companyId: string,subCompanyId: string ,policyNo: string ) :Observable<IRDocument[]> {
   
      // var token = localStorage.getItem('token');
     const httpparams = new HttpParams()
     .set('companyId', companyId)
     .set('subCompanyId', subCompanyId)
     .set('policyNo', policyNo);

     const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),params:httpparams }; 
     return this.http.get<IRDocument[]>(this.baseUrl + 'ImageRight/GetInspectionDocuments/',httpOptions);  
       } 

       GetInspectionDocument(inspectionDocument:IRDocument) :Observable<Blob> {       
       const httpOptions = { responseType: 'blob' as 'json', headers: new HttpHeaders({ 'Content-Type': 'application/json' })}; 
       return this.http.post<Blob>(this.baseUrl + 'ImageRight/GetInspectionDocument/',inspectionDocument,httpOptions);  
         }
         
        
      UploadInspectionDocument(formdata:FormData) :Observable<HttpEvent<any>> {   
        const req = new HttpRequest('POST', `${this.baseUrl}ImageRight/UploadInspectionDocuments/`, formdata, {
          reportProgress: true,
          responseType: 'json'
        });   
        return this.http.request(req);
         
        } 
}