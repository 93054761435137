<nav class="navLogin">
    <div>
        <ul>
            &nbsp;
        </ul>
    </div>
</nav>
<div class="container">
<div *ngIf="user.isValidUser==1" >
    <p>User account activated Successfully. Please login.</p>
    <span class="signintab">
    <a href="#login.html"> Sign in</a>
    </span>
</div>
<div *ngIf="user.isValidUser==0">
    <p>Invalid url.</p>   
</div>
<div *ngIf="user.isValidUser==-1">
    <p>User account already activated.</p>   
</div>
</div>
