<div class="container">
<span class="outstand1">Available Documents</span>
<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Document Name</th>  
      <th scope="col">Date</th> 
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let document of documentList">
      <td><a href="javascript: void(0);" (click) = "GetDocument(document)">{{document.counter}}</a></td>
      <td><a   href="javascript: void(0);" (click) = "GetDocument(document)">{{document.documentName}}</a></td>
      <td>{{document.documentDate | date:'MM/dd/yyyy'}}</td>
    </tr>
  </tbody>
</table>
<div *ngIf="documentError">
  <p> Documents are not available</p>
  </div>
  </div>

