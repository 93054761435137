
<div class="dv">
<nav class="navbar navbar-light bg-dark txt dv1">
  Forgot Password
  <a [routerLink]="['/login']">Back to login</a>
</nav>
</div>
<div class="cnt">
  <div class="container card cardd">
    <div class="card-body">
      <form [formGroup]="validEmail" (ngSubmit)="onSubmit()">
        <div class="form-group textbox">
          <label>Email</label>
          <small id="emailHelp" class="form-text text-muted lbl1">Enter your email id to get a link to change the password</small>
          <input type="email" [(ngModel)]="user.userID" class="form-control ip1" id="exampleInputEmailID" aria-describedby="emailHelp" placeholder=""
                 formControlName="emailId" name="emailId">
          <div *ngIf="submitted && validEmail.controls.emailId.errors" class="invalid-feedback2">
            <div *ngIf=" validEmail.controls.emailId.errors.required">Email ID  is required</div>
          </div>
          <div *ngIf="!user.isValidEmail" class="invalid-feedback2">
            <div *ngIf="!user.isValidEmail">Please enter a valid email address.</div>
          </div>
        </div>
        <div class="float-right">
          <button [disabled]="validUserId" class="buttonlogin btn btn-sm btn-primary">
            Submit
          </button>
        </div>

      </form>
    </div>
  </div>

  <div *ngIf="validUserId && !emailFailed" class="showMessage">
    <p>User id is verified and mail has been sent to your email id.</p>
  </div>
  <div *ngIf="submitted && invalidUserId" class="showErrorMessage">
    <p>UserId is not valid</p>
  </div>
  <div *ngIf="emailFailed" class="showErrorMessage">
    <p>Failed due to technical issues. Please contact customer service.</p>
  </div>
</div>




   
