import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Inspection } from '../_models/inspection.model';
import { IRDocument } from '../_models/irdocument.model';
import { Tracker } from "../_models/tracker.model";
import { AuthenticationService } from "../_services/authentication.service";
import { InspectionService } from '../_services/inspection.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
    selector: 'app-inspection',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.css']
  })
  export class InspectionComponent implements OnChanges {
    @Input() policyNo:string;
   @Input() pMRSequence:string;
   public editContactDetails: any; 
 
    helper = new JwtHelperService();
    selectedValue:number;
    isInspection:boolean=false;
    isLinkOpened:boolean=false;
   
  selectedInspection: Inspection;
  inspectionContactInfo: Inspection;
  inspectionStatusInfo:Inspection;
  saveContactSuccess=false;
    submitContact:boolean=false;   
    companyId!:string;
    subCompanyId!:string;
    nameId!:string;
    contact1:string;
    contact2:string;
    telephoneHome:string;
    telephoneWork:string;
    geolocationNo:number;
  inspModel:Inspection={
    companyId: '',
    subCompanyId: '',
    nameId: '',
    contact1: '',
    contact2: '',
    telephoneH: '',
    telephoneW: '',
    policyNo: '',
    inspectionStatus: '',
    name: '',
    inspectorPhone: '',
    inspectionResult: '',
    geoLocationNo: 0,
    pmrSequence: '', 
    inspectSequence:0,
     inspectionTelephoneH:'',
    inspectionTelephoneW:'',
    inspectionDocuments: [],
    existingContact2:''
  };
  blob: Blob;
  documentAvailable: boolean;
  inspectionDocuments: IRDocument[];
  //selectedFiles:File[]; 
   selectedFiles:any; 
selectedUploadOption:string;
selectedSubUploadOption:string;
 formData: FormData = new FormData();
 fileNotFound:boolean=false;
 currentFile: File;
  progress = 0;
  message = '';
  fileUploaded:boolean=false;
  documentError:boolean=false;
  InspectionNotExists: boolean=false;
  userId: string = '';
  userName: string = '';
    isCsrUser: boolean;
  csrUserID: string;
  csrUserName: string;
  constructor( private authService: AuthenticationService,private modalService: NgbModal,
    private inspectionService:InspectionService,private formBuilder:FormBuilder,
    private spinner: NgxSpinnerService, private router:Router) { 
        this.selectedValue=0;     
    }
 
   ngOnChanges() 
  {
    this.documentError=false;
    this.editContactDetails=this.formBuilder.group({
      cName1:[""],
      cNumber1:[""],
      cName2:[""],
      cNumber2:[""]
    },);

     const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    this.companyId=environment.companyCode;
    this.subCompanyId=environment.companyCode;
    this.nameId=decodedToken.NameID;
    this.userId=decodedToken.UserID;
     this.userName = decodedToken.Name;

     this.isCsrUser = (decodedToken.IsCSR) ? true : false;
     this.csrUserID = decodedToken.CsrUserID;
     this.csrUserName = decodedToken.CsrName;

    this.inspModel.companyId=this.companyId;
    this.inspModel.subCompanyId=this.subCompanyId;
    this.inspModel.nameId=this.nameId;
    this.inspModel.pmrSequence=this.pMRSequence;     
    this.inspModel.policyNo=this.policyNo;
    this.documentError=false;
    this.PageOnLoad();   
  }
  ngOnInit(): void {
    const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    this.userId = decodedToken.UserID;
    this.userName = decodedToken.Name;

   
      this.isCsrUser = (decodedToken.IsCSR)? true:false;
      this.csrUserID = decodedToken.CsrUserID;
      this.csrUserName = decodedToken.CsrName;
   
  }
PageOnLoad(){
  this.progress = 0;
  this.message = '';
  this.currentFile = undefined;
  this.GetInspectionStatusDetails(this.inspModel);
  this.LoadInspectionDocuments();
}
 
LoadInspectionDocuments(){
  this.inspectionService.GetInspectionDocuments(this.inspModel.companyId, this.inspModel.subCompanyId, this.inspModel.policyNo)
  .subscribe(
   (response) => {
     this.inspectionDocuments=response;
     if(this.inspectionDocuments.length<=0)
     this.documentError=true;
      console.log('GetInspectionDocuments');
     //console.log( response)
    })
}
        openUploadPopup(content: any,option:any) {
          this.progress = 0;
          this.message = '';
          this.currentFile = undefined;
          this.formData = new FormData();
          this.selectedFiles = undefined;
         // console.log("option"+option.textContent);
          this.selectedSubUploadOption = option.textContent;
            this.modalService.open(content,{ centered: true , size: 'lg' });
          }

          OpenPhoneUpload(phoneContent:any){
            this.modalService.open(phoneContent,{centered:true, size: 'lg'});
          }

          DisplayInspection(){
            this.isInspection=true;
          }

          OpenUpload(){
           
            this.isLinkOpened=true;
          }
     UpdateInspectionContactDetails()
      {
        
        //this.submitContact=true;
        if(this.editContactDetails.invalid)
        {
          return;
        }
        this.inspModel.companyId=this.companyId;
        this.inspModel.subCompanyId=this.subCompanyId;
       this.inspModel.nameId = this.nameId;
       /*tracker*/
       if (this.inspModel.contact2 != this.inspModel.existingContact2) {
         const tracker: Tracker = {
           companyId: this.companyId,
           subCompanyId: this.subCompanyId,
           insuredNameId:this.nameId,
           insuredUserName: this.userName,
           userId: (!this.isCsrUser) ? this.userId : this.csrUserID,
           userName: (!this.isCsrUser) ? this.userName : this.csrUserName,
           actionType: 'Phone Number Update',
           actionDescription:'',
           policyNo: this.policyNo,
           isCSR: this.isCsrUser || false

         }
         this.inspModel.tracker = tracker;
       }
       console.log('Name id in the Update inspection');
      // console.log(this.inspModel.nameId);
        this.inspectionService.SaveInspectionContactDetails(this.inspModel).subscribe(
          response => {
         
           if(response){
             //console.log(response);
             this.saveContactSuccess=true;
             this.submitContact=true;
             
           }
           else{
            this.submitContact=true;
            this.saveContactSuccess=false;
            
           }
          },
          error=>{
            console.log(error);
            this.saveContactSuccess=false;
            this.submitContact=true;
          }
        );
      }
     
      GetInspectionStatusDetails(inspection:Inspection)
      {
        this.submitContact=false;
        this.InspectionNotExists=false;
        this.inspectionService.GetInspectionStatusDetails(inspection)
        .subscribe(
          response=>{
            if(response){
             // console.log('GetInspectionStatusDetails'+response);
              this.inspModel=response;
              this.inspModel.inspectSequence = response.inspectSequence;
              if(this.inspModel.contact1==null && this.inspModel.contact2==null && this.inspModel.inspectionTelephoneH==null && this.inspModel.inspectionTelephoneW==null){  
                this.InspectionNotExists=true;
              }
              this.inspModel.existingContact2 = JSON.parse(JSON.stringify(response.contact2));
             
            }
          },
          error=>{
            console.log(error);
            this.InspectionNotExists=true;
          }
          
        );     
       
      }

      
     GetInspectionDocument(selectedDocument:IRDocument){
       // console.log(selectedDocument)
       selectedDocument.companyId=this.inspModel.companyId;
       selectedDocument.subCompanyId = this.inspModel.subCompanyId;

       //tracker
       
       const tracker: Tracker = {
         companyId: this.companyId,
         subCompanyId: this.subCompanyId,
         insuredNameId: this.nameId,
         insuredUserName: this.userName,
         userId: (!this.isCsrUser) ? this.userId : this.csrUserID,
         userName: (!this.isCsrUser) ? this.userName : this.csrUserName,
         actionType: 'View Document',
         actionDescription: '',
         policyNo: this.policyNo,
         //effDate:this.
         isCSR: this.isCsrUser || false

       }
       selectedDocument.tracker = tracker;

      this.spinner.show();
      let newWindow = window.open();
      newWindow.document.write('loading ...');
       this.inspectionService.GetInspectionDocument(selectedDocument)
       .subscribe( 
         response => {
           if(response!=null){
             this.spinner.hide();
          this.blob = new Blob([response], {type: 'application/pdf'});
           let url = URL.createObjectURL(this.blob);
           this.router.navigate([url]);
           newWindow.location.href = url;//POPULATING PDF s
           }
           else{
            this.spinner.hide();
             this.documentAvailable=false;
           }

          
             
       },
        error => {
          this.spinner.hide();
          this.documentAvailable=false;
         console.log(error);
       });
      }

  onUploadSelectChange(event:any) {
        this.selectedUploadOption = event.target.options[event.target.options.selectedIndex].text;
        
  }

  SelectFiles(files: FileList) {
    this.progress = 0;
    this.message = '';
    this.currentFile = undefined;
      this.selectedFiles = [];
      this.formData = new FormData();
        //let dict=[];
        for (let i = 0; i < files.length; i++) {
          
          let fileIndex = i + 1;
          this.formData.append(files[i].name,files[i]);
          this.formData.append("FileIndex", fileIndex.toString());
          this.formData.append("FileName", files[i].name);       
          this.selectedFiles.push({file:files[i],comment:'',name:files[i].name});
         //this.selectedFiles.push(files[i]);
          //dict.push({name:files[i].name ,file:files[i],comment:'test'});
        }
        this.formData.append("PolicyNo", this.policyNo);
        this.formData.append("GeoLocationNo",this.inspModel.geoLocationNo.toString());
        this.formData.append("InspectionSeq",this.inspModel.inspectSequence.toString());
        this.formData.append("SelectedReason",this.selectedUploadOption);
        this.formData.append("SelectedSubReason1",this.selectedSubUploadOption);
        this.formData.append("CompanyId",this.companyId);
        this.formData.append("SubCompanyId",this.subCompanyId);
      
        //alert(this.inspModel.inspectSequence);
      }

      UploadFiles(){

        if(!this.selectedFiles){
         
          this.fileNotFound = true;
          console.log(this.fileNotFound);
          return;
        }

        let comments:string='';
        this.progress = 0;

        this.selectedFiles.forEach((element:any)  => {
          comments += element['comment'] +'|';
        });
        this.formData.append('comments',comments);
      
      
          this.inspectionService.UploadInspectionDocument(this.formData).subscribe(
            event => {
              if (event.type === HttpEventType.UploadProgress) {
                this.currentFile = this.selectedFiles[0].name;
                this.progress = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
                this.progress = 0;
                this.message = 'File uploaded successfully'; 
                this.currentFile = undefined;
                this.selectedFiles = undefined;
                this.fileUploaded = true;
              }
            },
          err =>{
              console.log(err);
              this.progress = 0;
              this.message = 'File upload failed!';
              this.currentFile = undefined;
              this.formData = new FormData();
            }
          );    
  }

  ClearForm(inspection:Inspection){
    inspection.contact1=null;
    inspection.contact2=null;
    inspection.inspectionTelephoneH=null;
    inspection.inspectionTelephoneW=null;
    inspection.policyNo=this.policyNo;
    inspection.pmrSequence=this.pMRSequence;
    inspection.nameId=this.nameId;
    inspection.companyId=inspection.subCompanyId=this.companyId;
    this.saveContactSuccess=false;
    this.GetInspectionStatusDetails(inspection);
  }
   

  }
