

<nav style="background-color: black;width: 100%;">

    <div>

        <ul class="navText">
            Create a new account

        </ul>
    </div>
</nav>

<div class="scroll">

  <div class="formDiv">
    <form class="borderForm" *ngIf="!registrationSuccess && !registrationFailed">
      <h1 class="headingForm">
        Create Account
      </h1>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="Name">Name</label>
          <input [(ngModel)]="user.name" type="name" class="form-control1" id="exampleInputName" placeholder="Enter name" name="userName" formControlName="userName">
          <div *ngIf="submitted && registerForm.controls.userName.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.userName.errors.required">Name  is required</div>
          </div>
        </div>


        <div class="form-group">
          <label for="">Online Access Code</label>
          <input type="name" [(ngModel)]="user.onlineAccessCode" class="form-control1" id="accessCode" placeholder="Access Code" name="accessCode" formControlName="accessCode" (focusout)="validateOnlineAccesscode();validatePolicyNoAndAccesscode()">
          <div *ngIf="submitted && registerForm.controls.accessCode.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.accessCode.errors.required">Access code  is required</div>
          </div>
          <div *ngIf="!user.isValidAccessCode" class="invalid-feedback2">
            <div *ngIf="!user.isValidAccessCode">Please enter a valid Online Access Code</div>
          </div>
        </div>

        <div class="form-group">
          <label>
            Policy Number
          </label>
          <input type="" [(ngModel)]="user.policyNumber" class="form-control1" id="exampleInputPolicyNumber" placeholder="Policy Number" formControlName="policyNumber" (focusout)="validatePolicyNoAndAccesscode()">
          <div *ngIf="submitted && registerForm.controls.policyNumber.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.policyNumber.errors.required">Policy Number  is required</div>
          </div>
          <div *ngIf="user.isValidPolicyNumber" class="invalid-feedback2">
            <div *ngIf="user.isValidPolicyNumber">Access code do not match with policy number</div>
          </div>
        </div>

        <div class="form-group">
          <label>
            Email
          </label>
          <input type="email" [(ngModel)]="user.userID" class="form-control1" id="exampleInputEmailID" placeholder="Email Id" formControlName="emailId" (focusout)="GetEmailValidationStatus()">
          <div *ngIf="submitted && registerForm.controls.emailId.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.emailId.errors.required">Email ID  is required</div>
          </div>
          <div *ngIf="user.emailValidationStatus==='invalid' || validationFailed" class="invalid-feedback2">
            <div>{{this.emailValidationMessage }}</div>
          </div>
          <div *ngIf="user.isRegisteredEmail" class="invalid-feedback2">
            <div *ngIf="user.isRegisteredEmail">This email address is already registered</div>
          </div>
        </div>

        <div class="form-group">
          <label>
            Password
          </label>
          <input name="password" type="password" [(ngModel)]="user.password" class="form-control1" id="exampleInputPassword"
                 placeholder="Enter your Password" formControlName="password">
          <div *ngIf="submitted && registerForm.controls.password.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.password.errors.required">Password is required</div>
          </div>
          <span class="text-danger"
                *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.invalidPassword">
            Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
            letter and a number or special character
          </span>
        </div>

        <div class="form-group">
          <label>
            Reenter Password
          </label>
          <input type="password" [(ngModel)]="user.confirmPassword" class="form-control1"
                 id="exampleInputReenterPolicyNumber" formControlName="confirmPassword"
                 placeholder="Confirm your Password">
          <div *ngIf="submitted && registerForm.controls.confirmPassword.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.confirmPassword.errors.required">Please reenter the password</div>
          </div>
          <div *ngIf="registerForm.controls.password.touched && registerForm.controls.confirmPassword.errors" class="invalid-feedback2">
            <div *ngIf="registerForm.controls.password.touched && registerForm.controls.confirmPassword.errors.mustMatch">Password not matching</div>
          </div>
        </div>
        <!-- (focusout)="validatePassword()" -->

        <div class="form-group form-check">

          <input [(ngModel)]="checked" type="checkbox" class="form-check-input" formControlName="terms" id="exampleCheck1" required>
          <label class="form-check-label" for="exampleCheck1">
            I am the policy holder or an authorized
            person
          </label>
        </div>
        <p>
          By clicking submit, you are confirming that you have read and agree to our <a [routerLink]="" (click)="openTermsAndConditions(terms)"
                                                                                        style="color:dodgerblue">Terms of Use and Privacy Statement</a>
        </p>
        <button [disabled]="!checked" type="submit" class="btn btn-primary1">Submit</button>

        <!-- [disabled]="!registerForm.valid" -->
        <span class="signintab">
          <a href="#login.html">Already have an account ? Sign in</a>

        </span>
        <ng-template #terms let-modal>
          <div class="modal-header">
            Insured Portal
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- class="modal-body modal-lg" -->
          <div class="modal-lg">
            <iframe *ngIf="iframeTerms" id="pdfViewer1" [src]="iframeTerms" style="width: 100%; height: 400px;" zindex="100"></iframe>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
          </div>
        </ng-template>
        <ng-template #errorMsg let-modal>
          <div class="modal-header">

            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- class="modal-body modal-lg" -->
          <div class="modal-body">
            Registration failed. Please refresh the page and try again or contact customer service

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
          </div>
        </ng-template>
      </form>
    </form>
  </div>
  <div *ngIf="registrationSuccess">
    <p> User registered successfully. Please click the account activation link in the email sent to you to activate your account.</p>
    <span class="signintab">
      <a href="#login.html"> Sign in</a>

    </span>
  </div>
   <div *ngIf="registrationFailed && !emailValidationFailed">
    <p> Registration failed due to technical issues. Please contact customer service.</p>
  </div>
  <div *ngIf="emailValidationFailed">
    <p> Invalid email address! Failed to send activation link. Please contact customer service.</p>
  </div>
</div>
        
        <ng-template #emailerrorMsg let-modal>
            <div class="modal-header">
            
              <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button> -->
            </div>
            <!-- class="modal-body modal-lg" -->
            <div class="modal-body"  >
                Is this the valid email address {{this.user.userID}}?
               
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="validateEmail() ;modal.dismiss('Cross click')">Yes</button>
                <button type="button" class="btn btn-light" (click)="setValidationMessage();modal.dismiss('Cross click')" >No</button>
              </div>
          </ng-template>
