import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { User } from "../_models/user.model";
import { UserService } from "../_services/user.service";
import { CustomValidator } from "../_helpers/customValidator";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpParams } from "@angular/common/http";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ViewChild } from '@angular/core';

@Component({ templateUrl: "register.component.html", styleUrls: ['./register.component.css'] })

export class RegisterComponent implements OnInit {
  @ViewChild('errorMsg') errorMsg: any;
  @ViewChild('emailerrorMsg') emailerrorMsg: any;

  checked: boolean = false;
  public registerForm: any;
  loading = false;
  submitted = false;
  validAccessCode: boolean = false;
  invalidPassword: boolean = false;
  agree = false;
  registrationSuccess: boolean = false;
  registrationFailed: boolean = false;
  emailValidationFailed: boolean = false;
  blob: Blob;
  termsVersion: string;
  iframeTerms: SafeResourceUrl = undefined;
  deviceInfo: null;
  termsFailed: boolean = false;
  emailValidationMessage: string;
  validationFailed: boolean = false;
  loadingNeverBounceEmail: boolean = false;
  user: User = {
    companyID: '', subcompanyID: '', name: '', nameID: '', onlineAccessCode: '',
    policyNumber: '', password: '', userID: '', isValidEmail: true, isRegisteredEmail: false,
    deviceType: '', termsVersion: '', userUpdatedPassword: '', userUpdatedEmail: '', telephoneH: '', telephoneW: '',
    isValidAccessCode: true, isValidPolicyNumber: false, confirmPassword: '', activationCode: '',
    isValidUser: 0, isInActiveUser: false, isValidUserId: true, UpdatePwd: true, noofAttempts: 0, isVersionUpdated: false, versionDate: new Date(),
    emailValidationStatus: ''
  };




  constructor(private deviceService: DeviceDetectorService, private sanitizer: DomSanitizer, private modalService: NgbModal, private formBuilder: FormBuilder, private router: Router, private userService: UserService) { }

  ngOnInit() {

    this.GetTermsDocument();
    this.registerForm = this.formBuilder.group({
      userName: ["", [Validators.required]],
      accessCode: ["", [Validators.required]],
      policyNumber: ["", [Validators.required]],
      emailId: ["", [Validators.required]],
      password: ["", [Validators.required, new CustomValidator().patternValidator()]],
      terms: [false, [Validators.required]],
      confirmPassword: ["", Validators.required]
    }, {
      validator: new CustomValidator().MatchPassword('password', 'confirmPassword')
    });
    this.user.companyID = environment.companyCode;
    this.user.subcompanyID = environment.companyCode;
  }


  onSubmit() {
    this.submitted = true;
    this.emailValidationFailed = false;
    //console.log("version" +this.termsVersion);
    const data = {
      name: this.user.name,
      onlineAccessCode: this.user.onlineAccessCode,
      policyNo: this.user.policyNumber,
      password: this.user.password,
      email: this.user.userID
    };
    if (this.registerForm.invalid) {
      console.log(this.registerForm.invalid);
      return;
    }


    if (this.user.isRegisteredEmail) {
      console.log('Email already registered');
      return;
    }
    if (this.emailValidationMessage != '') {
      console.log(this.emailValidationMessage);
      console.log('Never bounce Email failed');
      return;
    }

    if (this.user.isValidPolicyNumber || this.user.isValidAccessCode === false) {
      //alert('policy'+this.user.isValidPolicyNumber);
      //alert('access'+this.user.isValidAccessCode);
      console.log('policynumber/accesscode failed');
      return;
    }

    if (this.termsVersion == null) {
      console.log('Terms failed');
      this.modalService.open(this.errorMsg);
      return;
    }
    this.user.nameID = this.user.onlineAccessCode.substring(1);
    this.user.deviceType = this.deviceService.deviceType;
    this.user.termsVersion = this.termsVersion;

    this.userService.RegisterUser(this.user)
      .subscribe(
        (response: any) => {
          this.emailValidationFailed = !("emailValidation_Success" in response);

          if ("update_Success" in response && "mailService_Success" in response) {
            this.submitted = true;
            this.registrationSuccess = true;           
          }
          else {
            this.registrationFailed = true;
          }
        },
        error => {
          console.log('Save user failed');
          this.registrationFailed = true;
        });
  }

  validateOnlineAccesscode() {
    this.user.isValidAccessCode = false;

    if (this.user.onlineAccessCode === null) {
      console.log('Accesscode null');
      return;
    }
    this.userService.ValidateOnlineAccessCode(this.user)
      .subscribe(
        (response) => {
          //console.log(response);
          this.user.isValidAccessCode = response.isValidAccessCode;

        },
        error => {
          console.log('validateOnlineAccesscode failed');
        });
  }

  validatePolicyNoAndAccesscode() {

    if (this.user.policyNumber === '' || this.user.policyNumber === null) {
      console.log('Policyno null');
      return;
    }
    const data = {
      name: this.user.name,
      onlineAccessCode: this.user.onlineAccessCode,
      policyNo: this.user.policyNumber.trim(),
      password: this.user.password,
      email: this.user.userID
    };
    this.user.policyNumber = this.user.policyNumber.trim();
    this.userService.ValidatePolicyNoAndAccesscode(this.user)
      .subscribe(
        response => {
          //console.log('component response');
          //console.log(response);
          this.user.isValidPolicyNumber = response.isValidPolicyNumber;
        },
        error => {
          console.log('validatePolicyNoAndAccesscode failed');
        });
  }

  validateEmail() {
    this.user.isRegisteredEmail = false;
    this.userService.ValidateEmail(this.user)
      .subscribe(

        response => {
          //console.log(response);
          this.user.isRegisteredEmail = response.isRegisteredEmail;
        },
        error => {
          console.log('validateEmail failed');
        });
  }

  GetEmailValidationStatus() {
    this.loadingNeverBounceEmail = true;
    this.user.isRegisteredEmail = false;
    this.emailValidationMessage = '';
    this.userService.GetEmailValidationStatus(this.user)
      .subscribe(

        response => {
          // console.log('GetEmailValidationStatus'+response);
          this.user.emailValidationStatus = response.emailValidationStatus;
          console.log(this.user.emailValidationStatus);
          if (this.user.emailValidationStatus == 'invalid') {
            this.loadingNeverBounceEmail = false;
            this.emailValidationMessage = 'This email address ' + this.user.userID + ' failed the validation process, please enter a valid email address';
          }
          else if (this.user.emailValidationStatus != 'invalid' && this.user.emailValidationStatus != 'valid') {
            console.log('Validation Fails');
            this.loadingNeverBounceEmail = false;
            this.modalService.open(this.emailerrorMsg);
            return;
          }
          else {
            this.loadingNeverBounceEmail = false;
            this.validateEmail();
          }
        },
        error => {
          this.loadingNeverBounceEmail = true;
          console.log('GetEmailValidationStatus failed');
        });
  }

  setValidationMessage() {
    this.validationFailed = true;
    this.emailValidationMessage = 'Please enter a valid email address';

  }

  validatePassword() {
    if (this.user.password != '' && (this.user.password != this.user.confirmPassword)) {
      this.invalidPassword = true;
    }
    else {
      this.invalidPassword = false;
    }
  }
  openTermsAndConditions(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }
  GetTermsDocument() {

    const httpparams = new HttpParams()
      .set('companyId', environment.companyCode)
      .set('subCompanyId', environment.companyCode);
    this.userService.GetTermsDocuments(httpparams)
      .subscribe(
        response => {
          //console.log(response.headers); 
          if (response != null) {
            const contentDispositionHeaderResult = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
            this.termsVersion = contentDispositionHeaderResult.replace(/"/g, '');

            this.blob = new Blob([response.body], { type: 'application/pdf' });
            let blobUrl: string = window.URL.createObjectURL(this.blob);
            this.iframeTerms = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl + "#toolbar=0");
          }

        },
        error => {
          console.log('Get terms document failed');
        });
  }
}


