import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Inspection } from "../_models/inspection.model";
import { IRDocument } from "../_models/irdocument.model";


@Injectable({
    providedIn: 'root'
  })
export class DocumentService{
    baseUrl = environment.apiUrl;
    blob: Blob;
    constructor(private http: HttpClient,private router: Router) { }

 
    GetDocuments(companyId: string,subCompanyId: string ,policyNo: string ) :Observable<IRDocument[]> {
   
      // var token = localStorage.getItem('token');
     const httpparams = new HttpParams()
     .set('companyId', companyId)
     .set('subCompanyId', subCompanyId)
     .set('policyNo', policyNo);

     const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),params:httpparams }; 
     return this.http.get<IRDocument[]>(this.baseUrl + 'ImageRight/GetDocumentList/',httpOptions);  
       } 

       GetDocumentData(inspectionDocument:IRDocument) :Observable<Blob> {       
       const httpOptions = { responseType: 'blob' as 'json', headers: new HttpHeaders({ 'Content-Type': 'application/json' })}; 
       return this.http.post<Blob>(this.baseUrl + 'ImageRight/GetInspectionDocument/',inspectionDocument,httpOptions);  
         } 
   
}