import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CustomValidator } from '../_helpers/customValidator';
import { User } from '../_models/user.model';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  public validEmail: any; 
  loading=false;
  submitted = false;
  validUserId:boolean=false;
  invalidUserId: boolean=false;
   emailFailed: boolean=false;

 user: User = {
   companyID: '', subcompanyID: '', name: '', nameID: '', onlineAccessCode: '',
   policyNumber: '', password: '', userID: '', isValidEmail: true, isRegisteredEmail: false,
   deviceType: '', termsVersion: '', userUpdatedPassword: '', userUpdatedEmail: '', telephoneH: '', telephoneW: '', isValidAccessCode: false, isValidPolicyNumber: false, confirmPassword: '', activationCode: '', isValidUser: 0, isInActiveUser: false, isValidUserId: false,
   UpdatePwd: false, noofAttempts: 0, isVersionUpdated: false, versionDate: new Date(),
   emailValidationStatus: ''
 };
  
  constructor(private modalService: NgbModal,private formBuilder: FormBuilder, private router: Router, private userService : UserService) { }

  ngOnInit(): void { 
    this.validEmail= this.formBuilder.group({
      emailId: ["", [Validators.required]],

    });
    this.user.companyID = environment.companyCode;
    this.user.subcompanyID = environment.companyCode;
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.user.userID);
      const data = { 
        email : this.user.userID
      };
      if (this.validEmail.invalid) {
        console.log(this.validEmail.invalid);
        return;
    } 
    this.userService.ValidateUserId(this.user)
          .subscribe(
            (response:any) => {
              if(response){
                this.submitted=true;
                this.validUserId=response.isValidUserId;
                if(response.isValidUserId==false)
                {
                  this.invalidUserId=true;
                }
                if (!("mailService_Success" in response.successList)) {
                  this.emailFailed = true;
                }
              }  
              else{
                  this.invalidUserId=true;
                 }       
            },
            error => {
              console.log(error);
              this.invalidUserId=true;
            });
           

    }
 

}
