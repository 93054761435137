<div class="headerUserDetails no-print">
    <div class="currentDate">
        <span> {{ dateToday | date }} </span>
    </div>
    <div class="headerUserName">
        <span>Welcome {{name}}</span>
    </div>
    <div class="headerAgentDetails">
       
        <span>Online Access Code : {{onlineAccessCode}}</span>
    </div>
  </div>
<nav class="navbar navbar-expand-lg navbar-light  navbar-dark bg-dark nvHome flex-row dt2">
    <a routerLink="/dashboard" class="navbar-brand" style="color: white;"><span class="sr-only">(current)</span>Home</a>
  
    <div class=" navbar-collapse flex-row" id="navbarNav">
      <ul class=" navbar-nav  flex-wrap ms-md-auto flex-row float-right">
        <li class="nav-item w-25 col-md-auto">
          <a routerLink="/editprofile" title="Edit Profile">
            <img src="assets/images/edit.png">
          </a>
        </li>
        <li class="nav-item w-25 col-md-auto">
          <a (click)='LogOut()' title="Log Out">
            <img src="assets/images/signOut.png">
          </a>
        </li>
      </ul>
    </div>
  </nav>