import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from "../_services/authentication.service";
import { environment } from '../../environments/environment';
import { UserService } from '../_services/user.service';
import { User } from "../_models/user.model";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidator } from "../_helpers/customValidator";
import { CsrService } from '../_services/csr.service';
import { CsrUser } from "../_models/csr.model";
import { Tracker } from '../_models/tracker.model';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditProfileComponent implements OnInit {
  public editForm: any;
  public editEmail: any;
  public editPwd: any;
  helper = new JwtHelperService();
  name !: string;
  onlineAccessCode !: string;
  companyID!: string;
  subCompanyID!: string;
  nameID!: string;
  dateToday: number;
  nameInDB: string;
  submitPersonal = false;
  submitEmail = false;
  existinguserID: string;
  existingTelephoneH: string;
  existingTelephoneW: string;
  isDisabledEmail: boolean = true;
  disabledPersonal: boolean = true;
  submitPassword = false;
  loader = false;
  invPwd = false;
  savePwdSuccess = false;
  saveProfileSuccess = false;
  saveEmailSuccess = false;
  isCsrUser = false;
  csrUserID: string;
  unlockSuccess = false;
  showMsg: boolean = false;
  showPwdMsg: boolean = false;
  showProfileMsg: boolean = false;
  csrUserName: string;
  tracker: Tracker;
  user: User = {
    companyID: '', subcompanyID: '', name: '', nameID: '', onlineAccessCode: '',
    policyNumber: '', password: '', userID: '', isValidEmail: true, isRegisteredEmail: false,
    deviceType: '', termsVersion: '', userUpdatedPassword: '', userUpdatedEmail: '', telephoneH: '', telephoneW: '', existingTelephoneH: '', existingTelephoneW: '', isValidAccessCode: true, isValidPolicyNumber: false, confirmPassword: '', activationCode: '',
    isValidUser: 0, isInActiveUser: false, isValidUserId: true, UpdatePwd: true, noofAttempts: 0, isVersionUpdated: false, versionDate: new Date(),
    emailValidationStatus: ''
  };

  csrUsr: CsrUser = {
    companyID: '', subcompanyID: '', nameID: '', name: '', noofAttempts: 0, csrUserID: '',
    csrPassword: '', insuredEmailID: '', onlineAccessCode: '', insuredUpdatedEmailID: '',
  };
 
  userID: string;

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,
    private authService: AuthenticationService, private userService: UserService, private csrservice: CsrService) { }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      userName: ["", [Validators.required]],
      workph: [""],
      homeph: [""]

    });


    this.editEmail = this.formBuilder.group({
      userID: ["", [Validators.required]]
    });
    this.editPwd = this.formBuilder.group({
      password: ["", [Validators.required]],
      newPassword: ["", [Validators.required, new CustomValidator().patternValidator()]],
      confirmPassword: ["", [Validators.required]],
    }, {
      validator: new CustomValidator().MatchPassword('newPassword', 'confirmPassword')
    });
    const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    this.name = decodedToken.Name;
    this.onlineAccessCode = decodedToken.Accesscode;
    this.nameID = decodedToken.NameID;
    this.companyID = environment.companyCode;
    this.subCompanyID = environment.companyCode;
    this.dateToday = Date.now();
    this.user.nameID = this.nameID;
    this.user.companyID = this.companyID;
    this.user.subcompanyID = this.subCompanyID;
    this.isCsrUser = decodedToken.IsCSR ||false;
    this.csrUserID = decodedToken.CsrUserID;
    this.csrUserName = decodedToken.CsrName;
    this.userID = decodedToken.UserID;

    console.log(decodedToken);
   

    this.tracker = {
      companyId: this.companyID,
      subCompanyId: this.subCompanyID,
      insuredNameId:  this.nameID,
      insuredUserName:  this.name,
      userId: (!this.isCsrUser) ? this.userID : this.csrUserID,
      userName: (!this.isCsrUser) ? this.name : this.csrUserName,
      actionType: 'Phone Number Update',
      actionDescription: '',
      policyNo: '',
      isCSR: (!this.isCsrUser) ? false : true

    }



    this.userService.GetUserProfileDetails(this.user)
      .subscribe(
        (response) => {
          //  console.log(response);


          if (response != null) {
            this.user = response;
            // console.log(this.user.telephoneH);
            this.existinguserID = this.user.userID;
            this.user.existingTelephoneH = JSON.parse(JSON.stringify(this.user.telephoneH));
            this.user.existingTelephoneW = JSON.parse(JSON.stringify(this.user.telephoneW));
          }

        },
        error => {
          console.log(error);
        });

  }

  ngOnChanges() {
    const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    this.tracker.userId  = decodedToken.UserID;
  }

  SavePersonalInfo() {
    this.submitPersonal = true;
    if (this.editForm.invalid) {
      return;
    }
    //console.log('Name in db'+this.nameInDB);
    //console.log('Name in page'+this.user.name);
    this.user.companyID = this.companyID;
    this.user.subcompanyID = this.subCompanyID;
    this.user.nameID = this.nameID;
   
    this.userService.UpdateUserProfile(this.user)
      .subscribe(
        response => {
          if (response) {
            this.saveProfileSuccess = true;
            this.submitPersonal = true;
            this.disabledPersonal = true;
            this.showProfileMsg = true;
          }
          else {
            console.log('failed');
            this.submitPersonal = false;
            this.showProfileMsg = true;
            this.saveProfileSuccess = false;
          }
        },
        error => {
          console.log(error);
          this.submitPersonal = false;
          this.showProfileMsg = true;
          this.saveProfileSuccess = false;
        });
  }

  UpdateEmail() {
    console.log('updateemail');
    this.submitEmail = true;
    if (this.editEmail.invalid) {

      return;
    }
    if (this.user.userID == this.existinguserID) {
      return;
    }
    this.user.companyID = this.companyID;
    this.user.subcompanyID = this.subCompanyID;
    this.user.nameID = this.nameID;
    this.user.userUpdatedEmail = this.user.userID;
    this.user.userID = this.existinguserID;
    this.user.tracker = this.tracker;

    if (this.isCsrUser)  // update email by CSR user
    {
      this.csrUsr.companyID = this.companyID;
      this.csrUsr.subcompanyID = this.subCompanyID;
      this.csrUsr.nameID = this.nameID;
      this.csrUsr.insuredUpdatedEmailID = this.user.userUpdatedEmail;
      this.csrUsr.insuredEmailID = this.existinguserID;
      this.csrUsr.csrUserID = this.csrUserID;
      this.csrUsr.csrName = this.csrUserName;
      this.csrUsr.name = this.name;
     


      this.csrservice.UpdateEmail(this.csrUsr)
        .subscribe(
          (response:any) => {
            if ("update_Success" in response && "emailValidation_Success" in response) {
                         
              this.saveEmailSuccess = true;
              this.submitEmail = false;
              this.isDisabledEmail = true;
              this.user.userID = this.user.userUpdatedEmail;
            }
            else {
              console.log('failed');
              this.submitEmail = false;
              this.saveEmailSuccess = false;
              this.showMsg = true;
            }
          },
          error => {
            console.log(error);
            this.submitEmail = false;
            this.saveEmailSuccess = false;
            this.showMsg = true;
          });
    }
    else {     //logged in user update email section

      this.userService.UpdateEmail(this.user)
        .subscribe(
          (response:any) => {
            if ("update_Success" in response && "emailValidation_Success" in response) {
              this.saveEmailSuccess = true;
              this.submitEmail = false;
              this.isDisabledEmail = true;
              this.user.userID = this.user.userUpdatedEmail;
              this.showMsg = true;
            }
            else {
              console.log('failed');
              this.submitEmail = true;
              this.saveEmailSuccess = false;
              this.showMsg = true;
            }
          },
          error => {
            console.log(error);
            this.submitEmail = false;
            this.saveEmailSuccess = false;
            this.showMsg = true;
          });
    }
  }
  ChangePassword() {
    this.invPwd = false;
    this.showPwdMsg = false;
    this.submitPassword = true;
    this.loader = true;
    this.savePwdSuccess = false;
    if (this.editPwd.invalid) {
      this.loader = false;
      return;
    }
    this.user.companyID = this.companyID;
    this.user.subcompanyID = this.subCompanyID;
    this.user.userID = this.existinguserID;
    this.userService.ValidatePassword(this.user)
      .subscribe(
        response => {
          if (response) {
            this.UpdatePassword();
          }
          else {
            this.invPwd = true;
            this.loader = false;
          }
        },
        error => {
          console.log(error);
          //this.submitEmail=false;
        });
  }
  UpdatePassword() {
    this.userService.UpdatePassword(this.user)
      .subscribe(
        response => {
          if (response) {
            this.savePwdSuccess = true;
            this.submitPassword = false;
            this.loader = false;
            this.editPwd.reset();
            this.showPwdMsg = true;
          }
          else {
            this.submitPassword = false;
            this.loader = false;
            this.editPwd.reset();
            this.savePwdSuccess = false;
            this.showPwdMsg = true;
          }
        },
        error => {
          console.log(error);
          this.loader = false;
          this.editPwd.reset();
          this.savePwdSuccess = false;
          this.showPwdMsg = true;
        });
  }

  LogOut() {
    this.authService.Logout(this.isCsrUser);
  }
  openScrollableContent(longContent: any) {
    this.showPwdMsg = false;
    this.savePwdSuccess = false;
    this.modalService.open(longContent, { scrollable: true });
  }

  UnlockAccount() {
    this.user.companyID = this.companyID;
    this.user.subcompanyID = this.subCompanyID;
    this.user.nameID = this.nameID;

    this.csrservice.UnlockAccount(this.user, this.csrUserID)
      .subscribe(
        response => {
          if (response) {
            this.unlockSuccess = true;
            this.loader = false;
            this.user.noofAttempts = 0;
          }
          else {


          }
        },
        error => {
          console.log(error);

        });
  }
}
