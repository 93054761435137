import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {CsrUser} from '../_models/csr.model';
import {environment} from '../../environments/environment'
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import {User} from '../_models/user.model';

  @Injectable({
    providedIn: 'root'
  })
  export class CsrService {
     
     baseUrl = environment.apiUrl;
  
    constructor(private http: HttpClient,private router: Router) { }
  
    helper = new JwtHelperService();
     
  Login(user: CsrUser): Observable<any> {  
    console.log(user);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
    return this.http.post<CsrUser>(`${environment.apiUrl}csr/ValidateCSRLogin/`,  
      user, httpOptions).pipe(
          map((response: any) => {
          if (response!=null && response.token!=null){
            localStorage.setItem('token', response.token);
         }  
         return response;  
                 
     })
     );  
  }
  UnlockAccount(user: User,csrUserID: string):Observable<boolean>{
    const httpparams = new HttpParams()
    .set('csrUserID', csrUserID);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),params:httpparams }; 
    return this.http.post<boolean>(this.baseUrl + 'csr/UnlockUser/',  
    user, httpOptions);  
  }
  UpdateEmail(user: CsrUser):Observable<boolean>{
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'csr/UpdateEmail/',  
    user, httpOptions);  
  }
  }

