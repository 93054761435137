import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot,UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from "../_services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthenticationService,public router: Router
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      this.authService.AutoLogoutTimer();

    if (this.authService.IsLoggedIn()) {
      console.log('authguard token true');
      return true;
    }
    
    const isTokenRefreshSuccess = await this.RefreshToken();
    if (!isTokenRefreshSuccess) {
      console.log('authguard refresh token false');
      window.alert("Your session expired. Please log in");
      this.authService.Logout();
    }
    return isTokenRefreshSuccess;
 
  }

  private async RefreshToken(): Promise<boolean> {
    const token = localStorage.getItem("token");
    const refreshToken: string = localStorage.getItem("refreshToken");
    if (!token || !refreshToken) { 
      return false;
    }
    const credentials = JSON.stringify({ accessToken: token, refreshToken: refreshToken });
    let isRefreshSuccess: boolean;
    try {
      const response = await this.authService.GetRefreshTokenAsync(credentials);
     
      if(response!=null){
        //alert(response);
        isRefreshSuccess = true;
      }
     else{
      isRefreshSuccess = false;
     }
    }
    catch (ex) {      
      isRefreshSuccess = false;
    }
    return isRefreshSuccess;
  }
}