import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UseractivationComponent } from './useractivation/useractivation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from "./shared/auth.guard";
import { ChangePasswordComponent } from './change-password/change-password.component';
import {EditProfileComponent} from './editProfile/editprofile.component';
import { CsrLoginComponent } from './csr/csrlogin.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'user/activate', component: UseractivationComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard]},
  { path:'user/changePassword', component:ChangePasswordComponent},
  { path: 'editprofile', component: EditProfileComponent,canActivate: [AuthGuard]},
  { path: 'csrlogin', component: CsrLoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
