
<div class="dv">
<nav class="navbar navbar-light bg-dark txt dv1">
  Change Password
</nav>
</div>

<div class="cnt">
 <div class="container card cardd "  *ngIf="user.isValidUser==1  && !passwordUpdated" >     <!-- *ngIf="user.isValidUser==1  && !passwordUpdated" -->
 
  <div class="card-body">
    
    <form  [formGroup]="validPassword" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>New Password</label>
        <input type="password" class="form-control ip1" name="password" [(ngModel)]="user.password" id="exampleInputPassword"
         aria-describedby="emailHelp" formControlName="password" name="password" required="true" >
         <div *ngIf="submitted && validPassword.controls.password.errors" class="invalid-feedback2" >
          <div *ngIf="validPassword.controls.password.errors.required" >Password is required</div>
      </div>
      <span class="text-danger"
              *ngIf="validPassword.controls.password.touched && validPassword.controls.password.errors?.invalidPassword">
              Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
              letter and a number or special character
          </span>
          
      </div>
      <div class="form-group">
        <label>Confirm Password</label>
        <input type="password" class="form-control ip1" name="confirmPassword" [(ngModel)]="user.confirmPassword" 
        id="exampleInputReenterPolicyNumber" aria-describedby="emailHelp" formControlName="confirmPassword" name="confirmPassword"
         required="true">
        <div *ngIf="submitted &&validPassword.controls.confirmPassword.errors" class="invalid-feedback2" >
          <div *ngIf="validPassword.controls.confirmPassword.errors.required" >Please reenter the password</div>
      </div>
      <div *ngIf="validPassword.controls.password.touched && validPassword.controls.confirmPassword.errors" class="invalid-feedback2" >
          <div *ngIf="validPassword.controls.password.touched && validPassword.controls.confirmPassword.errors.mustMatch" >Password not matching</div>
      </div> 
      </div>
      <div class="float-right">
        <button  type="submit" class="btn btn-primary" aria-pressed="true"  >Submit</button>
      </div>
     
  </form>
  </div>
</div>


<div *ngIf="user.isValidUser==0" class="invalid-feedback2">
  <p>User cannot access this functionality either the url or user is invalid</p>   
</div>
<div *ngIf="user.isValidUser==2" class="invalid-feedback2">
  <p>User cannot access this functionality as the url is expired</p>   
</div>
<div *ngIf="passwordUpdated" class="showMessage">
  <p>Password updated successfully! Please click<a [routerLink]= "['/login']" [queryParams]="{pwdupdated:true}"> here </a>to login back.</p>   

</div>
<div *ngIf="errorMessage" class="invalid-feedback2">
 Error occurred while processing the request.Please try again.
</div>
</div>


 

