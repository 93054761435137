import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CustomValidator } from '../_helpers/customValidator';
import { User } from '../_models/user.model';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
 validPassword: any; 
 submitted = false;
 activationCode: any;
 passwordUpdated: boolean=false;
 user: User = {
   companyID: '', subcompanyID: '', name: '', nameID: '', onlineAccessCode: '',
   policyNumber: '', password: '', userID: '', isValidEmail: true, isRegisteredEmail: false,
   deviceType: '', termsVersion: '', userUpdatedPassword: '', userUpdatedEmail: '', telephoneH: '', telephoneW: '', isValidAccessCode: true, isValidPolicyNumber: false, confirmPassword: '', activationCode: '',
   isValidUser: 0, isInActiveUser: false, isValidUserId: true, UpdatePwd: true, noofAttempts: 0, isVersionUpdated: false, versionDate: new Date(),
   emailValidationStatus: ''
 };
 errorMessage: boolean;

  constructor(private modalService: NgbModal,private formBuilder: FormBuilder, private router: Router, private userService : UserService,private router1 : ActivatedRoute) {

   }

  ngOnInit(): void {
    this.router1.queryParams.subscribe(params => {
      console.log(params);
      this.user.activationCode=params['key'];
      if(this.user.activationCode!=null && this.user.activationCode!='undefined'){
        this.activateChangePassword(this.user.activationCode);
      }
      
    });
    this.validPassword= this.formBuilder.group({
      password: ["", [Validators.required,new CustomValidator().patternValidator()]],
      confirmPassword:["", Validators.required]
    },{
      validator: new CustomValidator().MatchPassword('password', 'confirmPassword')
    });
    this.user.companyID = environment.companyCode;
    this.user.subcompanyID = environment.companyCode;
  }
  onSubmit() {
    this.submitted = true;
      const data = {
        
        password : this.user.password,

       
      };

      this.user.userUpdatedPassword= this.user.password;
      if (this.validPassword.invalid) {
        console.log(this.validPassword.invalid);
        return;
       
    }
    console.log('before UpdatePassword method' );
     console.log(this.user);
    this.userService.UpdatePasswordFP(this.user)
          .subscribe(
            response => {
              if(response){
                this.passwordUpdated=true;
                
              }
                 else{
                 this.errorMessage=true;
                 }       
            },
            error => {
              console.log(error);
              this.errorMessage=true;
            });
    }

  activateChangePassword(activationCode: any){
      if(activationCode =='undefined' || activationCode=='' ){
        return;
        console.log('this.activationCode value'+ activationCode)
      }
      else{
      this.userService. ActivateChangePassword(this.user)
      .subscribe(
            (response) => {
            this.user.isValidUser = response.isValidUser;
            this.user.userID=response.userID;
            this.user.activationCode="";
            this.activationCode="";
            if( this.user.isValidUser==1){
            this.router.navigate(['/user/changePassword']);
            }
            console.log(response);      
            console.log(this.user.isValidUser);            
             },
           error => {
             console.log(error);
             
  });
      }
   
}   

}