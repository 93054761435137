import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {User} from '../_models/user.model';
import {environment} from '../../environments/environment'
import { Router } from '@angular/router';

  @Injectable({
    providedIn: 'root'
  })
  export class UserService {
        
     baseUrl = environment.apiUrl;
  
    constructor(private http: HttpClient,private router: Router) { }
  
    RegisterUser(user: User): Observable<User> {  
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/RegisterUser/',  
        user, httpOptions);  
    }

    ValidateOnlineAccessCode(user: User): Observable<User> {  
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/ValidateOnlineAccessCode/',  
        user, httpOptions);  
    }
    
    ValidatePolicyNoAndAccesscode(user: User): Observable<User> {  
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/ValidatePolicyNoAndAccesscode/',  
        user, httpOptions);  
    }

    ValidateEmail(user: User): Observable<User> {  
      console.log('userservice');
      //console.log(user);
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/ValidateEmail/',  
        user, httpOptions);  
    }
    
    ActivateUser(user: User): Observable<User> {  
      //console.log(user);
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/ActivateUser/',  
        user, httpOptions);  
    }

     
    ResendActivationLink(user: User): Observable<any> {  
      //console.log(user);
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/ResendActivationLink/',  
        user, httpOptions);  
    }
    ValidateUserId(user: User): Observable<User> {  
     
      //console.log(user);
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
      return this.http.post<User>(this.baseUrl + 'User/ValidateUserId/',  
        user, httpOptions);  
    }

    ActivateChangePassword(user:User):Observable<User>{
     // console.log(user);
      const httpOptions={headers:new HttpHeaders({'Content-Type':'application/json'})};
      return this.http.post<User>(this.baseUrl+'User/ActivateChangePasswordLink/',user,httpOptions);
    }
  

 GetUserProfileDetails(user: User): Observable<User> {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
      return this.http.post<User>(this.baseUrl + 'User/GetUserProfileDetails/',  
      user, httpOptions);  
    }
  UpdateUserProfile(user: User):Observable<boolean>{
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'User/UpdateUserProfile/',  
    user, httpOptions);  
  }
  UpdateEmail(user: User):Observable<boolean>{
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'User/UpdateEmail/',  
    user, httpOptions);  
  }
  ValidatePassword(user: User): Observable<boolean> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'User/ValidatePassword/',  
    user, httpOptions);  
  }

  UpdatePassword(user: User): Observable<boolean> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'User/UpdatePassword/',  
    user, httpOptions);  
  }

  UpdatePasswordFP(user: User): Observable<boolean> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'User/UpdatePasswordFP/',  
    user, httpOptions);  
  }
  GetTermsDocuments(paramObj?: any) :Observable<any> {
    
    return this.http.get(this.baseUrl + 'User/GetTermsDoc/', {
      params:paramObj, 
      responseType: 'arraybuffer',
     observe: 'response'
  });

     } 

     
     UpdateTermsVersion(user: User): Observable<boolean> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<boolean>(this.baseUrl + 'User/UpdateTermsVersion/',  
    user, httpOptions);  
  }

  GetEmailValidationStatus(user: User): Observable<User> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
    return this.http.post<User>(this.baseUrl + 'User/GetEmailValidationStatus/',  
    user, httpOptions);
  }

  }

