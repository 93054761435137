import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minusToParenthesis'
})
export class MinusToParenthesisPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value!=null && value!=undefined && value!=''){
    return value.charAt(0) == '-' ?
           '(' + value.substring(1, value.length) + ')' :
           value;
    }
    
  }

}



