
 <div class="container">
      <div class="row mt-0 ">
    
        <div class="col-lg-6 " >
          <div class="card " style="width: 100%; ">
    
            <div class="card-body ">
              <span class="card-title mt-0" >Inspection</span>
              <span class="spanofPolicy"><b>Current Status: {{inspModel?.inspectionStatus}}</b></span>
              <span class="spanofPolicy"><b>Inspector Name: {{inspModel?.name}}</b></span> 
              <span class="spanofPolicy"><b>Inspector Phone: {{inspModel?.telephoneW}} </b></span>
              <span class="spanofPolicy"><b>Result: {{inspModel?.inspectionResult}}</b></span>
            
            </div>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="card " style="width: 100%; text-align: left;">
    
            <div class="card-body">
              <span class="headAgent card-title">Contact for Inspection</span>
              <div>
     
                <span class="spanofPolicy"><b>Inspection Contact Name: {{inspModel?.contact1!=null && inspModel?.contact1!=' ' && inspModel.contact1!='undefined'?inspModel?.contact1:'No contact name on file for inspection'}}</b></span>
                <span class="spanofPolicy"><b>Inspection Contact Phone: {{inspModel?.contact2!=null && inspModel?.contact2!=' ' && inspModel?.contact2!='undefined' ? inspModel?.contact2 :'No contact phone on file for inspection'}}</b></span>
                <span class="spanofPolicy"><b>Insured Primary Phone: {{inspModel?.inspectionTelephoneH!=null && inspModel?.inspectionTelephoneH!=' ' && inspModel?.inspectionTelephoneH!='undefined' ? inspModel?.inspectionTelephoneH :'No insured primary contact number on file for inspection'}}</b></span>
                <span class="spanofPolicy"><b>Insured Secondary Phone:{{inspModel?.inspectionTelephoneW!=null && inspModel?.inspectionTelephoneW!=' ' && inspModel?.inspectionTelephoneW!='undefined' ? inspModel?.inspectionTelephoneW :'No insured secondary contact number on file for inspection'}} </b></span>
              <button class="btn btn-primary btn-sm btnUpdate" (click)="OpenPhoneUpload(phoneContent)" > Update</button>
      
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start available documents -->
      <div class="row mt-4 mb-4 border">
      <span class="outstand1 mt-0">Available Documents</span>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Document Name</th>  
          </tr>
        </thead>
        <tbody>
          
          <tr *ngFor="let inspectionDocument of inspectionDocuments">
            <td><a href="javascript: void(0);" (click) = "GetInspectionDocument(inspectionDocument)">{{inspectionDocument.counter}}</a></td>
            <td><a   href="javascript: void(0);" (click) = "GetInspectionDocument(inspectionDocument)">{{inspectionDocument.documentName}}</a></td>

          </tr>
          
        </tbody>
      </table>
      <div *ngIf="documentError">
        <p>Inspection Documents are not available</p>
        </div>

    </div> 
    <!-- end available document section -->
    <!-- start upload documents -->
 <div class="row mb-4 border ">
  <span class="outstand1 mt-0">Upload Documents to Respond to Inspection Correspondence</span>
           <div class="row mt-3 pb-3">
                <div class="col-2">  
                  <label for="qsn" class="font-weight-normal">I want to:  </label>
                </div>
              
              <div class="col-4">
                  <select  class="form-select uploadDropdown" (change)="onUploadSelectChange($event)"  [(ngModel)]="selectedValue"  >
                  
                    <option  value="0" selected >Select an Option</option>
                      <option value="1">Respond to a lockout letter?</option>
                      <option value="2">Respond to a Substandard Condition Notice?</option>
                      <option value="3">Respond to a Remedial Condition Notification Letter?</option>
                      <option value="4">Respond to a Notice of Cancellation Notice?</option>
                      <option value="5">Respond to a Notice of Intent not to Renew?</option>
                    
                  </select>
                </div>
            </div>
                <div class="questions" *ngIf="selectedValue == 1"><p #option1>An inspection must be requested via <a href="javascript:void(0)" class="links"  (click)="openUploadPopup(uploadDocuments,option1)">Click here to upload documents</a></p>
                <p>When requesting inspection, please make sure that the telephone number listed above is current. </p></div>
                <div class="questions" *ngIf="selectedValue == 2">
                    <p #option2>We request that you correct the conditions indicated within thirty days from the date of receipt of this notice.
                      Should the noted conditions be corrected,
                      please provide photographic evidence of the corrected conditions. Photos can be submitted via <a class="links" href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option2)">Link to upload</a> .</p> 
                    <p #option3>If you need additional time to correct the conditions indicated on substandard condition letter,
                      you may submit a request of extension via <a class="links" href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option3)">Link to Upload</a>. Request should include how much time is needed to complete repairs.</p>
                </div>
                <div class="questions" *ngIf="selectedValue == 3">
                  <p #option4>The Association does not require that the condition(s) be corrected at this time. Should the noted conditions be corrected,
                    please provide photographic evidence of the corrected conditions. Photos or documentation can be submitted via <a class="links"href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option4)">Link to Upload</a> .</p>
                </div>
                <div class="questions" *ngIf="selectedValue == 4">
                  <p #option5>When the Notice of Cancellation is for occupancy or eligibility reason(s),
                    please submit documentation or request a reinspection via <a class="links"href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option5)">Link to Upload</a>.</p>
                  <p #option6>When the Notice of Cancellation is for sub-standard conditions, please provide photographic evidence of the corrected conditions.
                    Photos can be submitted via <a class="links"href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option6)">Link to Upload</a>.</p>
                  <p #option7>If additional time is needed to complete repairs, you can submit an extension request by <a class="links"href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option7)">clicking here</a>.</p>
                </div>
                <div class="questions" *ngIf="selectedValue == 5">
                  <p #option8>When the Notice of Intent not to Renew is for occupancy or eligibility, 
                  please submit documentation or request a reinspection via <a class="links" href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option8)">Link to Upload</a>.</p>
                  <p #option9>When the Notice of Intent not to Renew is for sub-standard conditions, please provide photographic evidence of the corrected conditions.
                      Photos can be submitted via <a class="links"href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option9)">Link to Upload</a>.</p>
                      <p #option10>If additional time is needed to complete repairs, you can submit an extension request via <a class="links" href="javascript:void(0)" (click)="openUploadPopup(uploadDocuments,option10)">Link to Upload</a>.</p>
                </div>
            
            
          </div>
  
</div>
<!-- end upload documents -->
  <ng-template #uploadDocuments let-modal>
    <div class="modal-header ">
      <h2 class="modal-title">Upload Documents</h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body ">
      <div>
          <input type="file" 
          #fileInput
           multiple 
           (change)="SelectFiles(fileInput.files)"          
            style="margin-right: 0%; ">
          </div>
              <div *ngIf="fileNotFound" class="invalid-feedback2"  >Select a file to upload</div>
              <div  *ngIf="currentFile" class="progress mt-3" >
                <div
                  class="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  attr.aria-valuenow="{{ progress }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }"
                >
                  {{ progress }}%
                </div>
               
              </div>
              <div *ngIf="message" [className]="fileUploaded ? 'alert alert-success mt-3' : 'alert alert-danger mt-3'" role="alert">{{ message }}</div>
    </div>
    <form #formRef="ngForm">
  <div class="card mt-3">
    <div class="card-header">List of Files</div>
    <ul
      class="list-group list-group-flush"
      *ngFor="let item of selectedFiles; let i = index"
    >
      <li class="list-group-item">
        <label>{{ item.name }}</label>
        <input type="text" [(ngModel)]="selectedFiles[i].comment"  name="{{'filecomment'+ i}}" 
         placeholder="Comments" style="width: 35%; margin-left: 1%;"  >
      </li>
    </ul>
    
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="!selectedFiles" class="btn btn-primary" (click)="UploadFiles()">Upload</button> 
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Close</button>
</div>
    </form>
  </ng-template>
  
  <ng-template #phoneContent let-modal>
    <div class="modal-header ">
      <h2 class="modal-title">Update Contact Details</h2>
      <button type="button" class="close" aria-label="Close" (click)="ClearForm(inspModel);modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="editContactDetails" (ngSubmit)="UpdateInspectionContactDetails()">
     
      <div class="form-group">
          <label for="Name">Inspection Contact Name</label>
          <input  type="text" [(ngModel)]="inspModel.contact1" id="contactName1" name="cName1" formControlName="cName1" class="form-control">
      </div>
      <div class="form-group">
        <label for="AlternateName">Inspection Contact Phone</label>
        <input  type="text"  [dropSpecialCharacters]="false" [showMaskTyped] ="true" mask="(000)000-0000"  [(ngModel)]="inspModel.contact2" id="contactName2" name="cName2" formControlName="cName2" class="form-control">
    </div>
      <div class="form-group">
          <label for="ContactNumber">Insured Primary Phone</label>
          <input type="text" [dropSpecialCharacters]="false" [showMaskTyped] ="true" mask="(000)000-0000"  [(ngModel)]="inspModel.inspectionTelephoneH" id="contactNumber1" name="cNumber1" formControlName="cNumber1" class="form-control"  >   
        </div>
    
    <div class="form-group">
        <label for="AlternateNumber">Insured Secondary Phone</label>
        <input type="tel" [dropSpecialCharacters]="false" [showMaskTyped] ="true" mask="(000)000-0000-0000" [(ngModel)]="inspModel.inspectionTelephoneW" id="contactNumber2" name="cNumber2" formControlName="cNumber2" class="form-control"  >   
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="saveContactSuccess" >Update</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="ClearForm(inspModel);modal.dismiss('Cross click');">Close</button>
    </div>
      <div *ngIf="submitContact" [className]="saveContactSuccess ? 'alert alert-success mt-3' : 'alert alert-danger mt-3'" role="alert">
       {{saveContactSuccess? 'Contact details updated successfully.':'Update failed'}} 
       </div>  
    </form>
  </div>
  </ng-template>
  <ngx-spinner 
  bdColor = "rgba(0, 0, 0, 0.8)" 
  size = "medium" 
  color = "#fff" 
  type = "ball-atom" 
  [fullScreen] = "true">
  <p style="color: white" > Loading... </p>
  </ngx-spinner>