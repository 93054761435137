import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import {Policy} from "../_models/policy.model";
import {PolicyService} from "../_services/policy.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../environments/environment';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Agent } from "../_models/agent.model";
import { Invoice } from '../_models/invoice.model';
import { Payment } from '../_models/payment.model';
import { Address } from '../_models/address.model';
import { Coverage } from '../_models/coverage.model';
import { Endorsement } from '../_models/endorsement.model';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { Mortgagee } from '../_models/mortgagee.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from "../_services/authentication.service";
import { Claim } from '../_models/claim.model';
import { AdditionalInsured} from '../_models/additionalInsured.model';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  helper = new JwtHelperService();
  name !:string;
  onlineAccessCode !:string;
  companyID!: string;
  subCompanyID!: string;
  nameID!:string;
  userID!:string;
  activePolicies : Policy[] = [];
  dropdownPolicies : Policy[] = [];
  selectedPolicyData : Policy[] = [];
  selectedPolicy :Policy;
  selectedPolicyTerm:Policy;
  policyDetails :Policy;
  agentInfo :Agent;
  invoiceData : Invoice[];
  paymentData : Payment[];
  billingAddress :Address;
  billingData:string;
  mortgageeList:Mortgagee[];
  locationAddress: Address;
  locationAddressData:string;
  isBillingAddress:boolean=true;
  isLocationAddress:boolean=true;
  isMortgagees:boolean=true;
  coverageList:Coverage[];
  endorsementData:Endorsement[];
  whDeductible: any;
  isDeductible:boolean=true;
  isBillingTab:boolean=false;
  isPoliciesTab:boolean=false;
  noMortgagees: boolean=false;
  dateToday: number;
  insuredLocation: string;
  pendingCancel:boolean=false;
  isReportClaimTab:boolean=false;
  isClaims: boolean=true;
  claimList: Claim[];
  noClaims: boolean=false;
  isAddInsured:boolean=true;
  noAddInsured: boolean=false;
  addInsuredList:AdditionalInsured[];
  noBillingAddress:boolean=false;
  blob: Blob;
  totalDue:number;
  paymentDueDate:Date;
  overdue: boolean=false;
  documentAvailable: boolean=true;
  noLocationAddress: boolean=false;
  isCsrUser:boolean=false;
  isOtherPoliciestab: boolean=false;
  isPMRinPC:boolean=false;
  isInspectionTab:boolean=false;
  policyNo:string;
  pMRSequence:string;
  isDocumentsTab:boolean=false;
  otherPolicies:Policy[];
  validPolicies:Policy[];
  isPolicies: boolean=true;
  csrUserID: string;
  csrUserName: string;
  processingStatus:string;


  constructor( private router: Router, private policyService : PolicyService, private authService: AuthenticationService) { 
    
  }

  ngOnInit(): void {
    const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    
    this.name= decodedToken.Name;
    this.onlineAccessCode=decodedToken.Accesscode;
    this.nameID=decodedToken.NameID;
    this.userID = decodedToken.UserID;
    this.companyID = environment.companyCode;
    this.subCompanyID = environment.companyCode; 
    this.dateToday = Date.now();
    this.isCsrUser = decodedToken.IsCSR || false;

    this.csrUserID = decodedToken.CsrUserID;
    this.csrUserName = decodedToken.CsrName;
    
  
    this.policyService.GetActivePolicyList(this.companyID,this.subCompanyID,this.nameID)
    .subscribe(
     (response) => {
        console.log('GetActivePolicyList');
       //console.log( response);
      
       this.activePolicies=response;
       //this.pMRSequence=activePolicies;
       this.dropdownPolicies = this.activePolicies.filter(x => ( x.pmrStatus === 'IN' && x.processingStatus==='*') || (x.pmrStatus==='PC' && x.processingStatus==='I') || (x.pmrStatus==='PA' && x.processingStatus==='I'));
      this.selectedPolicy=this.dropdownPolicies[0];
      console.log('Selected policy');
      //console.log(this.selectedPolicy);
      this.pMRSequence=this.selectedPolicy.pmrSequence;
      this.policyNo=this.selectedPolicy.policyNo;
       
    if(this.activePolicies!=null && this.selectedPolicy!=null)
    {
     // this.selectedPolicy = this.activePolicies[this.activePolicies.findIndex(x =>  x.pmrStatus === 'IN')];
     this.selectedPolicyData=this.activePolicies.filter(x =>  x.policyNo === this.selectedPolicy.policyNo);
     //this.selectedPolicy= this.selectedPolicyData[this.selectedPolicyData.findIndex(x =>  x.pmrStatus === 'IN')];//this.selectedPolicyData.filter(x =>  x.pmrStatus === 'IN');
      this.selectedPolicyTerm=this.selectedPolicyData[this.selectedPolicyData.findIndex(x =>  ( x.pmrStatus === 'IN' && x.processingStatus==='*') || (x.pmrStatus==='PC' && x.processingStatus==='I') || (x.pmrStatus==='PA' && x.processingStatus==='I'))];
      if(this.selectedPolicyData.filter(x => x.pmrStatus === 'PC').length > 0){
        this.isPMRinPC = true;
       }
    
      this.isBillingTab=true;
     // alert(this.selectedPolicyTerm.pMRSequence);
     this.GetPolicyData(this.selectedPolicyTerm);
     this.GetAgentData(this.selectedPolicyTerm);
     this.GetInvoices(this.selectedPolicyTerm);
     this.GetPaymentHistory(this.selectedPolicyTerm);
     this.GetBillingAddress(this.selectedPolicyTerm);
     this.GetInsuredLocation(this.selectedPolicyTerm);
     this.GetAdditionalInsured(this.selectedPolicyTerm);
     this.GetMortgagees(this.selectedPolicyTerm);
     this.GetClaims(this.selectedPolicyTerm);
     this. GetOtherPolicies(this.activePolicies);
     if(this.validPolicies !=null && this.validPolicies != undefined && this.validPolicies.length>1)
     {
      this.isOtherPoliciestab=true;      
      this.otherPolicies = this.validPolicies.filter(x =>  x.policyNo !== this.selectedPolicyTerm.policyNo);

     }
    }

     },
   error => {
       console.log(error);
     });
         
  }
    
  changePolicy(index:number){
    //alert(this.pMRSequence);
    if(this.activePolicies!=null && this.selectedPolicy!=null)
    {
  
     // this.selectedPolicy = this.activePolicies[this.activePolicies.findIndex(x =>  x.pmrStatus === 'IN')];
     this.selectedPolicyData=this.activePolicies.filter(x =>  x.policyNo === this.selectedPolicy.policyNo);
     if(this.selectedPolicyData.filter(x => x.pmrStatus === 'PC').length > 0){
      this.isPMRinPC = true;
     }
     else{
      this.isPMRinPC = false;
     }
    
     if(index==0)
     {
      this.selectedPolicyTerm=this.selectedPolicyData[this.selectedPolicyData.findIndex(x => ( x.pmrStatus === 'IN' && x.processingStatus==='*') || (x.pmrStatus==='PC' && x.processingStatus==='I') || (x.pmrStatus==='PA' && x.processingStatus==='I'))];
     }
     //this.selectedPolicy= this.selectedPolicyData[this.selectedPolicyData.findIndex(x =>  x.pmrStatus === 'IN')];//this.selectedPolicyData.filter(x =>  x.pmrStatus === 'IN');
    }
    this.policyNo=this.selectedPolicyTerm.policyNo;
    this.pMRSequence=this.selectedPolicyTerm.pmrSequence;
    //alert(this.policyNo);
    //alert(this.pMRSequence);
    this.GetPolicyData(this.selectedPolicyTerm);
    this.GetAgentData(this.selectedPolicyTerm);
      this.GetInvoices(this.selectedPolicyTerm);
     this.GetPaymentHistory(this.selectedPolicyTerm);
     this.GetCoverages(this.selectedPolicyTerm);
     this.GetEndorsements(this.selectedPolicyTerm);
     this.GetWhDeductible(this.selectedPolicyTerm);
         
     this.GetBillingAddress(this.selectedPolicyTerm);
     this.GetInsuredLocation(this.selectedPolicyTerm);
     this.GetAdditionalInsured(this.selectedPolicyTerm);
     this.GetMortgagees(this.selectedPolicyTerm);
     this.GetClaims(this.selectedPolicyTerm);
     this.otherPolicies=this.activePolicies.filter(x =>  x.policyNo !== this.selectedPolicyTerm.policyNo);
    this.GetOtherPolicies(this.activePolicies);
    if(this.validPolicies !=null && this.validPolicies != undefined && this.validPolicies.length>1)
    {
     this.isOtherPoliciestab=true;      
     this.otherPolicies = this.validPolicies.filter(x =>  x.policyNo !== this.selectedPolicyTerm.policyNo);

    }
    this.isLocationAddress=true;
     this.isBillingAddress=true;
     this.isAddInsured=true;
     this.isClaims=true;
     this.isMortgagees=true;
     this.documentAvailable=true;
    
  }


  GetOtherPolicies(activePolicies:Policy[])
  {
    this.isPolicies=true;

    if(this.activePolicies.length>1){
      this.validPolicies= Array.from(new Set(activePolicies.map(a => a.policyNo)))
        .map(id => {
         return activePolicies.find(a => a.policyNo === id)});    
      

        }   
  }
  GetPolicyTabData(selectedPolicy:Policy)
  {
    this.isInspectionTab=false;
    this.isBillingTab=false;
    this.isPoliciesTab=true;
    this.isDocumentsTab=false;
    if(this.activePolicies!=null && this.activePolicies!=undefined)
    {
      if(this.selectedPolicy!=null)
      {
       // this.selectedPolicy = this.activePolicies[this.activePolicies.findIndex(x =>  x.pmrStatus === 'IN')];
       this.selectedPolicyData=this.activePolicies.filter(x =>  x.policyNo === this.selectedPolicy.policyNo);
       //this.selectedPolicy= this.selectedPolicyData[this.selectedPolicyData.findIndex(x =>  x.pmrStatus === 'IN')];//this.selectedPolicyData.filter(x =>  x.pmrStatus === 'IN')
      }

      this.GetPolicyData(this.selectedPolicyTerm);
    this.GetAgentData(this.selectedPolicyTerm);
    this.GetCoverages(this.selectedPolicyTerm);
    this.GetEndorsements(this.selectedPolicyTerm);
    this.GetWhDeductible(this.selectedPolicyTerm);
    this.GetBillingAddress(this.selectedPolicyTerm);
    this.GetInsuredLocation(this.selectedPolicyTerm);
    this.GetAdditionalInsured(this.selectedPolicyTerm);
    this.GetMortgagees(this.selectedPolicyTerm);
    this.GetClaims(this.selectedPolicyTerm);
    this. GetOtherPolicies(this.activePolicies);
    if(this.validPolicies !=null && this.validPolicies != undefined && this.validPolicies.length>1)
    {
     this.isOtherPoliciestab=true;      
     this.otherPolicies = this.validPolicies.filter(x =>  x.policyNo !== this.selectedPolicyTerm.policyNo);

    }
    }
    this.documentAvailable=true;
  }
  GetBillingTabData(selectedPolicy:Policy)
  {
    this.isInspectionTab=false;
    this.isPoliciesTab=false;
    this.isBillingTab=true;
    this.isDocumentsTab=false;
    //this.GetActivePolicies(this.companyID,this.subCompanyID,this.nameID);
    if(this.activePolicies!=null && this.activePolicies!=undefined)
    {
      if(this.selectedPolicy!=null)
      {
       // this.selectedPolicy = this.activePolicies[this.activePolicies.findIndex(x =>  x.pmrStatus === 'IN')];
       this.selectedPolicyData=this.activePolicies.filter(x =>  x.policyNo === this.selectedPolicy.policyNo);
       //this.selectedPolicy= this.selectedPolicyData[this.selectedPolicyData.findIndex(x =>  x.pmrStatus === 'IN')];//this.selectedPolicyData.filter(x =>  x.pmrStatus === 'IN');
      }
    this.GetPolicyData(this.selectedPolicyTerm);
    this.GetAgentData(this.selectedPolicyTerm);
    this.GetInvoices(this.selectedPolicyTerm);
    this.GetPaymentHistory(this.selectedPolicyTerm);
    this.GetBillingAddress(this.selectedPolicyTerm);
    this.GetInsuredLocation(this.selectedPolicyTerm);
    this.GetAdditionalInsured(this.selectedPolicyTerm);
    this.GetMortgagees(this.selectedPolicyTerm);
    this.GetClaims(this.selectedPolicyTerm);
    this. GetOtherPolicies(this.activePolicies);
    if(this.validPolicies !=null && this.validPolicies != undefined && this.validPolicies.length>1)
    {
     this.isOtherPoliciestab=true;      
     this.otherPolicies = this.validPolicies.filter(x =>  x.policyNo !== this.selectedPolicyTerm.policyNo);

    }
    }
    this.documentAvailable=true;

  }
 
  GetActivePolicies(companyId:string,subCompanyId:string,nameId:string){
  this.policyService.GetActivePolicyList(companyId,subCompanyId,nameId)
   .subscribe( 
     response => {
      this.activePolicies=response;
       console.log('GetActivePolicyList Response:');   
      // console.log(response);   
   },
    error => {
     console.log(error);
   });
  }


  GetPolicyData(selectedPolicy:Policy){
    console.log('GetPolicyData Request:');  
     //console.log(this.selectedPolicy)
   this.policyService.GetPolicyData(selectedPolicy)
    .subscribe( 
      response => {
        this.policyDetails=response;
       
       
        
        var insuredLocationAddress = this.policyDetails.insuredAddress1 + '<br />' +((this.policyDetails.insuredAddress2) ? this.policyDetails.insuredAddress2 +'<br />' : '') +((this.policyDetails.insuredAddress3) ? this.policyDetails.insuredAddress3 +'<br />' : '')+
        this.policyDetails.insuredCity + ', ' + this.policyDetails.insuredState + ' ' + this.policyDetails.insuredZipCode;
        console.log('GetPolicyData Response:');  
       // console.log(this.selectedPolicy);   
        this.insuredLocation= insuredLocationAddress;
        this.pendingCancel=this.policyDetails.pendingCancel;
      if(this.pendingCancel){
        this.policyDetails.policyStatus='Pending Cancellation Effective';

      }
      else{
        this.policyDetails.policyStatus=selectedPolicy.pmrStatus=="IN"?"Inforce":"Issued";
        this.policyDetails.cancellationEffDate=null;
      }

    },
     error => {
      console.log(error);
    });
      
      }
    
    GetAgentData(selectedPolicy:Policy){
       // console.log(this.selectedPolicy)
      this.policyService.GetAgentData(selectedPolicy)
       .subscribe( 
         response => {
          if(response){
           this.agentInfo=response;
           console.log('GetAgentData Response:');   
           //console.log(response);  
         } 
       },
        error => {
         console.log(error);
       });
         }

       
    GetInvoices(selectedPolicy:Policy){
      this.totalDue=0;
     // console.log(this.selectedPolicy)
    this.policyService.GetInvoices(selectedPolicy)
     .subscribe( 
       response => {
        if(response){
         this.invoiceData=response;
           
         if(this.invoiceData!=null && this.invoiceData.length>0)
         {
           this.paymentDueDate=this.invoiceData[0].dueDate;
           let today = new Date();
          // console.log(today);
          // console.log( this.paymentDueDate);
           if(new Date(this.paymentDueDate) < today){
             this.overdue=true;
           }

          for (let i = response.length - 1; i >= 0; i--) {
            this.totalDue+= response[i].totalDue;
          }

         
     }

         console.log('GetInvoices Response:');   
        // console.log(response); 
        }  
     },
      error => {
       console.log(error);
     });

    }
    GetPaymentHistory(selectedPolicy:Policy){
     // console.log(this.selectedPolicy)
    this.policyService.GetPaymentHistory(selectedPolicy)
     .subscribe( 
       response => {
        if(response){
         this.paymentData=response;
         console.log('GetPaymentHistory Response:');   
         //console.log(response); 
        }  
     },
      error => {
       console.log(error);
     });
    }

    GetBillingAddress(policy:Policy){
     console.log('GetBillingAddress Request:');   
     // console.log('GetBillingAddress PolicyNo:'+policy.policyNo);   
    this.policyService.GetBillingAddress(policy)
     .subscribe( 
       response => {
        if(response){
         this.billingAddress=response;
         if (this.isBillingAddress)
         {
            var billingAddressData = this.billingAddress.name + '<br />' +this.billingAddress.address1+ '<br />' + ((this.billingAddress.address2) ? this.billingAddress.address2 + '<br />' : '') +
            ((this.billingAddress.address3) ? this.billingAddress.address3 + '<br />' : '') +
            this.billingAddress.city + ', ' + this.billingAddress.state + ' ' + this.billingAddress.zipCode;
      
                        if (!this.billingAddress.address1 && !this.billingAddress.address2 && !this.billingAddress.city && !this.billingAddress.state && !this.billingAddress.zipCode) {
                          billingAddressData = null;
                        }
                        this.billingData=billingAddressData;
        }
      
                        if (!this.billingAddress.address1 && !this.billingAddress.address2 && !this.billingAddress.city && !this.billingAddress.state && !this.billingAddress.zipCode) {
                         this.noBillingAddress=true;
                        }
                     
                   //  console.log('noBillingAddress'+this.noBillingAddress);
         }  
     },
      error => {
       console.log(error);
     });
     return this.billingAddress;
    }

    DisplayBilingAddress(){
       this.isBillingAddress=!this.isBillingAddress;
        }  

    GetInsuredLocation(selectedPolicy:Policy){
    this.policyService.GetLocationAddress(this.selectedPolicy)
     .subscribe( 
       response => {
         
         this.locationAddress=response;      
      
                        if (!this.locationAddress.address1 && !this.locationAddress.address2 && !this.locationAddress.city && !this.locationAddress.state && !this.locationAddress.zipCode) {
                          this.noLocationAddress = true;
                        }
                     
                        if (this.isLocationAddress){
                          var locationAddressData = this.locationAddress.address1+ '<br />' + ((this.locationAddress.address2) ? this.locationAddress.address2 + '<br />' : '') +
                          ((this.locationAddress.address3) ? this.locationAddress.address3 + '<br />' : '') +
                          this.locationAddress.city + ', ' + this.locationAddress.state + ' ' + this.locationAddress.zipCode;
                    
                                      if (!this.locationAddress.address1 && !this.locationAddress.address2 && !this.locationAddress.city && !this.locationAddress.state && !this.locationAddress.zipCode) {
                                        locationAddressData = "No primary location listed";
                                      }
                                      this.locationAddressData=locationAddressData;
                         }        
     },
      error => {
       console.log(error);
     });
    }

    DisplayLocationAddress(){
      this.isLocationAddress=!this.isLocationAddress; 
    }
    
    GetMortgagees(selectedPolicy:Policy){
     // console.log(this.selectedPolicy)
    this.policyService.GetMortgagees(this.selectedPolicy)
     .subscribe( 
       response => {
         this.mortgageeList=response;
         if(this.mortgageeList==null || this.mortgageeList==undefined || this.mortgageeList.length==0)
         {
           this.noMortgagees=true;
         }
        // console.log('GetMortgagees Response:');   
       //  console.log(response);   
     },
      error => {
       console.log(error);
     });
    }

    DisplayMortgagees(){
      this.isMortgagees=!this.isMortgagees;

  }
    GetCoverages(selectedPolicy:Policy){
     // console.log(this.selectedPolicy)
    this.policyService.GetCoverages(this.selectedPolicy)
     .subscribe( 
       response => {
      this.coverageList=response;
         //console.log('GetCoverages Response:');   
       //  console.log(response);   
     },
      error => {
       console.log(error);
     });
     }

     GetEndorsements(selectedPolicy:Policy){
     // console.log(this.selectedPolicy)
    this.policyService.GetEndorsements(this.selectedPolicy)
     .subscribe( 
       response => {
      this.endorsementData=response;
       //  console.log('GetEndorsements Response:');   
      //   console.log(response);   
     },
      error => {
       console.log(error);
     });
     }

  GetWhDeductible(selectedPolicy:Policy){
     // console.log(this.selectedPolicy)
    this.policyService.GetWHDeductible(this.selectedPolicy)
     .subscribe( 
       response => {
      this.whDeductible=response.whDeductible;
      if(this.whDeductible=='See above Deduct' || this.whDeductible=='0' || this.whDeductible=='' )
      this.isDeductible=false;
         console.log('GetEndorsements Response:');   
        // console.log(response);   
     },
      error => {
       console.log(error);
     });
     }

     RedirecttoLossNotice(){
      this.isReportClaimTab=true;
       let url:string='';
       const insuredLocationAddress:string = this.policyDetails.insuredAddress1 + ((this.policyDetails.insuredAddress2) ? this.policyDetails.insuredAddress2 + ',' : '') ;
       const param:string = btoa(this.selectedPolicy.policyNo +'^'+ this.policyDetails.name +'^'+
       insuredLocationAddress  +'^'+ this.policyDetails.insuredCity  +'^'+
       this.policyDetails.insuredZipCode );
      if (this.companyID=='MPI'){
        url= environment.mpiProducerUrl +'#/loss-notice?p='+param+''; }
        else{
          url = environment.rijProducerUrl +'#/loss-notice?p='+param+'';}
        window.open(url, '_blank');
     }

     RedirecttoMakePayment(){
      let url:string='';  
     // commented on 12/26 since lastname should be fetched from namesortkey.As of now name is not passed to make a payment page.   
     // const param:string = btoa(this.selectedPolicy.policyNo +'^'+ this.policyDetails.name +'^'+ this.billingAddress.zipCode + '^' + this.policyDetails.minimumPayment);
       const param: string = btoa(this.selectedPolicy.policyNo + '^' + this.policyDetails.lastName + '^' + this.billingAddress.zipCode + '^' + this.policyDetails.minimumPayment);
     if (this.companyID=='MPI'){
       url= environment.mpiProducerUrl +'#/make-payment?p='+param+''; }
       else{
         url = environment.rijProducerUrl +'#/make-payment?p='+param+'';}
       window.open(url, '_blank');
    }

    GetClaims(selectedPolicy:Policy){
      //console.log(this.selectedPolicy)
      console.log('GetClaims Input:');   
    
    this.policyService.GetClaims(this.selectedPolicy)
     .subscribe( 
       response => {
         this.claimList=response;
         if(this.claimList==null || this.claimList==undefined  || this.claimList.length==0)
         {
           this.noClaims=true;
         }
         console.log('GetClaims Response:');   
         //console.log(response);   
     },
      error => {
       console.log(error);
     });
    }

 
    DisplayClaims(){
      this.isClaims=!this.isClaims;

  }

  DisplayOtherPolicies(){
    this.isPolicies=!this.isPolicies;

}

 GetAdditionalInsured(selectedPolicy:Policy){
    //  console.log(this.selectedPolicy)
      
     
    this.policyService.GetAddInsured(this.selectedPolicy)
     .subscribe( 
       response => {
         this.addInsuredList=response;
         if(this.addInsuredList==null || this.addInsuredList==undefined || this.addInsuredList.length<=0)
         {
           this.noAddInsured=true;
         }
         console.log('GetAddIns Response:');   
        // console.log(response);   
     },
      error => {
       console.log(error);
     });
    }
    




  DisplayAdditionalInsured(){
      this.isAddInsured=!this.isAddInsured;

  }
    GetInvoiceDocuments(selectedPolicy:Policy){
     // console.log(this.selectedPolicy)
      let trkUserId = (!this.isCsrUser) ? this.userID : this.csrUserID;
      let trkUserName = (!this.isCsrUser) ? this.name : this.csrUserName;
      let trkInsuredNameId =  this.nameID;
      let trkInsuredUserName = this.name;
     // this.selectedPolicy = this.activePolicies[this.activePolicies.findIndex(x =>  x.pmrStatus === 'IN' || x.pmrStatus==='PC')];
      this.policyService.GetInvoiceDocuments(this.selectedPolicy.companyID, this.selectedPolicy.subcompanyID, this.selectedPolicy.policyNo, trkUserId, trkInsuredNameId, trkUserName, trkInsuredUserName, this.isCsrUser, this.selectedPolicyTerm.pmrStatus)
     .subscribe( 
       response => {
         if(response!=null){
        this.blob = new Blob([response], {type: 'application/pdf'});

        const fileURL = URL.createObjectURL(response);
        window.open(fileURL, '_blank');
         }
         else{
           this.documentAvailable=false;
         }
           
     },
      error => {
        this.documentAvailable=false;
       console.log(error);
     });
    }

    GetPolicyDocuments(selectedPolicy:Policy){
   //   console.log(this.selectedPolicy)
      let trkUserId = (!this.isCsrUser) ? this.userID : this.csrUserID;
      let trkUserName = (!this.isCsrUser) ? this.name : this.csrUserName;
      let trkInsuredNameId = this.nameID;
      let trkInsuredUserName = this.name;


      this.policyService.GetPolicyDocuments(this.selectedPolicy.companyID, this.selectedPolicy.subcompanyID, this.selectedPolicy.policyNo, trkUserId, trkInsuredNameId, trkUserName, trkInsuredUserName,this.isCsrUser)
     .subscribe( 
       response => {
        if(response!=null){
        this.blob = new Blob([response], {type: 'application/pdf'});

        const fileURL = URL.createObjectURL(response);
        window.open(fileURL, '_blank');
      }
      else{
        this.documentAvailable=false;
      }
           
     },
      error => {
        this.documentAvailable=false;
       console.log(error);
     });
    }
    
    LogOut(){
      this.authService.Logout(this.isCsrUser);
    } 
    ShowInspection()
    {
      this.isInspectionTab = true;
      this.isBillingTab=false;
      this.isPoliciesTab=false;
      this.isDocumentsTab=false;
    }
    ShowDocuments()
    {
      this.isDocumentsTab=true;
      this.isInspectionTab=false;
      this.isBillingTab=false;
      this.isPoliciesTab=false;
    }
}

