import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { IRDocument } from '../_models/irdocument.model';
import { Tracker } from '../_models/tracker.model';
import { AuthenticationService } from '../_services/authentication.service';
import { DocumentService } from '../_services/document.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnChanges,OnInit {
  @Input() policyNo:string;
  helper = new JwtHelperService();
  companyId: string;
  subCompanyId: string;
  documentList: IRDocument[];
  blob: Blob;
  documentAvailable: boolean;
  documentError: boolean = false;
  userId: string;
  nameId: string;
  userName: string;

  isCsrUser: boolean;
  csrUserID: string;
  csrUserName: string;

  constructor( private authService: AuthenticationService,private documentService:DocumentService) { 
  }
  ngOnChanges() {
    console.log(this.policyNo);
    const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    

    this.companyId=environment.companyCode;
    this.subCompanyId=environment.companyCode;
     this.policyNo=this.policyNo;
     this.documentError=false;

    this.documentService.GetDocuments(this.companyId, this.subCompanyId, this.policyNo)
 .subscribe(
  (response) => {
    this.documentList=response;
    if(this.documentList.length<=0)
    this.documentError=true;
     console.log('GetDocuments');
    console.log( response)
   })
  }
  ngOnInit(): void {

    const decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
    this.userId = decodedToken.UserID;
    this.userName = decodedToken.Name;
    this.nameId = decodedToken.NameID;
    
    this.companyId=environment.companyCode;
    this.subCompanyId=environment.companyCode;
    this.policyNo = this.policyNo;


    this.isCsrUser = decodedToken.IsCSR || false;
    this.csrUserID = decodedToken.CsrUserID;
    this.csrUserName = decodedToken.CsrName;

     this.documentError=false;

    this.documentService.GetDocuments(this.companyId, this.subCompanyId, this.policyNo)
 .subscribe(
  (response) => {
    this.documentList=response;
    if(this.documentList.length<=0)
    this.documentError=true;
     console.log('GetDocuments');
    console.log( response)
   })
  }

  GetDocument(selectedDocument:IRDocument){
    console.log(selectedDocument)
    selectedDocument.companyId=this.companyId;
    selectedDocument.subCompanyId = this.subCompanyId;

    //tracker
   
    const tracker: Tracker = {
      companyId: this.companyId,
      subCompanyId : this.subCompanyId,
      insuredNameId: this.nameId,
      insuredUserName: this.userName,

      userId: (!this.isCsrUser) ? this.userId : this.csrUserID,
      userName: (!this.isCsrUser) ? this.userName : this.csrUserName,
      actionType: 'View Document',
      actionDescription: '',
      policyNo: this.policyNo,
      isCSR: this.isCsrUser ||false
    }
    selectedDocument.tracker = tracker;

    this.documentService.GetDocumentData(selectedDocument)
   .subscribe( 
     response => {
       if(response!=null){
      this.blob = new Blob([response], {type: 'application/pdf'});

      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, '_blank');
       }
       else{
         this.documentAvailable=false;
       }
         
   },
    error => {
      this.documentAvailable=false;
     console.log(error);
   });
  }

}
