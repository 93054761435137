import { isLoweredSymbol } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
  })
 
  export class HeaderComponent implements OnInit {
    constructor(
      public authService: AuthenticationService
    ) { }

    companyID = '';
    url='';
    isLoggedIn:boolean=false;
  ngOnInit(){
      this.companyID = environment.companyCode;
      this.url=window.location.hostname;
      if (this.authService.IsLoggedIn()) {
        console.log('set dashboard link');
        this.isLoggedIn = true;
      }
  }
  }