  <app-navigation-bar [isCsrUser]="isCsrUser" [name]="name" [onlineAccessCode]="onlineAccessCode"></app-navigation-bar>

  <!-- start Edit profile -->
  <div class="scroll">
  <div class="formDiv borderForm" >
     
        <h4 class="headingForm">
           My Profile
        </h4>
       
        <form  [formGroup]="editForm" (ngSubmit)="SavePersonalInfo()">
         
            <h4>
              Personal Information
              <span style="margin-left: 10px;"><a href="javascript://" (click)="this.disabledPersonal=!this.disabledPersonal">{{this.disabledPersonal ? 'Edit'  : 'Cancel' }}</a></span>
              <span *ngIf='isCsrUser && user.noofAttempts >= 5' style="float: right;"><a href="javascript://" (click)="UnlockAccount()">Unlock Account</a></span>
             </h4>
            
            <!-- <fieldset [disabled]="this.disabledPersonal">
             
            <div class="form-group">
                <label for="Name">Name</label>
                <input  type="name" [(ngModel)]="user.name" class="form-control" id="Name"  name="userName" formControlName="userName">
                <div *ngIf="submitPersonal && editForm.controls.userName.errors" class="invalid-feedback2" >
                    <div *ngIf="editForm.controls.userName.errors.required" >Name  is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="Name">Work Phone</label>
                <input  type="tel"  [(ngModel)]="user.telephoneW"  class="form-control" id="WorkPhone"  name="Workph" formControlName="workph">
               
               
            </div>
            <div class="form-group">
                <label for="Name">Home Phone</label>
                <input  type="name"  [(ngModel)]="user.telephoneH"  class="form-control" id="HomePhone"  name="homeph" formControlName="homeph">
               
               
            </div>
          
            <div>
                <button  type="submit" class="btn btn-primary1" >Save</button>
            </div>

        </fieldset> -->
        <!-- Without fieldset -->
        <div class="form-group">
          <label for="Name">Name</label>
          <input  type="name" [(ngModel)]="user.name" class="form-control" id="Name"  name="userName" formControlName="userName" [readonly]="this.disabledPersonal">
          <div *ngIf="submitPersonal && editForm.controls.userName.errors" class="invalid-feedback2" >
              <div *ngIf="editForm.controls.userName.errors.required" >Name  is required</div>
          </div>
      </div>
      <div class="form-group">
          <label for="Name">Work Phone</label>
          <input  type="tel"  [(ngModel)]="user.telephoneW"  class="form-control" id="WorkPhone"  name="Workph" formControlName="workph" [readonly]="this.disabledPersonal">
         
         
      </div>
      <div class="form-group">
          <label for="Name">Home Phone</label>
          <input  type="name"  [(ngModel)]="user.telephoneH"  class="form-control" id="HomePhone"  name="homeph" formControlName="homeph" [readonly]="this.disabledPersonal">
         
         
      </div>
    
      <div>
          <button  type="submit" class="btn btn-primary1" [disabled]="this.disabledPersonal">Save</button>
      </div>
        <!-- Without fieldset -->
        <div *ngIf="showProfileMsg" [className]="saveProfileSuccess ? 'alert alert-success mt-3' : 'alert alert-danger mt-3'" role="alert">
            {{saveProfileSuccess? 'Profile updated successfully':'Error in updating profile details'}} 
            
           </div>
           <div *ngIf="unlockSuccess" class="alert alert-success" role="alert">
           User Account unlocked successfully
           </div>
        </form>
    
     
  </div>
 
  <div class="formDiv borderForm space2 formDiv2" >
        <form [formGroup]="editEmail" (ngSubmit)="UpdateEmail()">
            <div class="form-group">
               
                <h4>Username
                <span style="margin-left: 10px;"><a href="javascript://" (click)="this.isDisabledEmail=!this.isDisabledEmail">{{this.isDisabledEmail ? 'Edit'  : 'Cancel' }}</a></span>
                <span style="float: right;"><a href="javascript://" (click)="openScrollableContent(chpwd)">Change Password</a></span>
              </h4>

              <div class="form-group">
                 <label for="Name">Email</label>
                <input [readonly]="this.isDisabledEmail"   type="text"  [(ngModel)]="user.userID"  class="form-control" formControlName="userID" id="UserEmail" >
                <div *ngIf="submitEmail && editEmail.controls.userID.errors" class="invalid-feedback2" >
                    <div *ngIf="editEmail.controls.userID.errors.required" >Email  is required</div>
                </div>
              </div>
          </div>
            <div>
                <button [disabled]="this.isDisabledEmail" type="submit" class="btn btn-primary1" >Save</button>
            </div>
            <div></div>
            <div *ngIf="showMsg" [className]="saveEmailSuccess ? 'alert alert-success mt-3' : 'alert alert-danger mt-3'" role="alert">
                {{saveEmailSuccess? 'Username updated successfully':'Error in updating Email.'}} 
                
               </div>
        </form>
    
  </div>
</div>
  <!-- start change password -->
  <ng-template #chpwd let-modal>
    <div class="modal-header ">
      <h4 class="modal-title">Change Password</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editPwd" (ngSubmit)="ChangePassword()">
          <div  *ngIf="!savePwdSuccess">
        <div class="form-group">
            <label for="Name">Current Password</label>
            <input  type="password" [(ngModel)]="user.password" class="form-control" id="password"  formControlName="password">
            <div *ngIf="submitPassword && editPwd.controls.password.errors" class="invalid-feedback2" >
                <div *ngIf="editPwd.controls.password.errors.required" >Password  is required</div>
            </div>
            <div *ngIf="submitPassword && invPwd" class="invalid-feedback2" >
                <div  >Password not matching with existing password</div>
            </div>
        </div>
        <div class="form-group">
            <label for="Name">New Password</label>
            <input type="password" [(ngModel)]="user.userUpdatedPassword"  class="form-control" id="nwPassword"  formControlName="newPassword">
            <div *ngIf="submitPassword && editPwd.controls.newPassword.errors" class="invalid-feedback2" >
                <div *ngIf="editPwd.controls.newPassword.errors.required" >New Password is required</div>
            </div>
            <span class="text-danger"
            *ngIf="editPwd.controls.newPassword.touched && editPwd.controls.newPassword.errors?.invalidPassword">
            Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
            letter and a number or special character
        </span>
        </div>
        <div class="form-group">
            <label for="Name">Confirm Password</label>
            <input type="password"  class="form-control" id="cfmPassword"  formControlName="confirmPassword">
            <div *ngIf="submitPassword && editPwd.controls.confirmPassword.errors" class="invalid-feedback2" >
                <div *ngIf="editPwd.controls.confirmPassword.errors.required" >Please re enter the password</div>
            </div>
            <div *ngIf="editPwd.controls.newPassword.touched && editPwd.controls.confirmPassword.errors" class="invalid-feedback2" >
                <div *ngIf="editPwd.controls.newPassword.touched && editPwd.controls.confirmPassword.errors.mustMatch" >Password not matching</div>
            </div> 
        </div>
        <div class="modal-footer">
            <button *ngIf="!loader" type="submit" class="btn btn-primary" >Save</button>
            
            <button *ngIf="loader" class="btn btn-primary" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
              
          </div>
        </div>
       
          <div *ngIf="showPwdMsg" [className]="savePwdSuccess ? 'alert alert-success mt-3' : 'alert alert-danger mt-3'" role="alert">
            {{savePwdSuccess? 'Password changed successfully':'Error in updating Password.'}}
           </div>
      </form>
    </div>
   
  </ng-template>
  

  