<nav class="navLogin">
    <div>
        <ul>
            &nbsp;
        </ul>
    </div>
</nav>
<div class="container">
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="flex-container">
    <div class="loginSection" >
        <h1 class="userIcon">
            <i class="fa fa-user" aria-hidden="true"></i>
        </h1>
        <div class="textbox">
            <i class="fa fa-user" aria-hidden="true">
            </i>
            <input type="text" [(ngModel)]="csrUser.csrUserID"  placeholder="User ID" name="LoginEmail" formControlName="LoginEmail">
            <div *ngIf="submitted && loginForm.controls.LoginEmail.errors"  class="invalid-feedback2">
                <div *ngIf="loginForm.controls.LoginEmail.errors.required" >User name is required</div>
             
            </div>
        </div>
       
            <div class="textbox">
            <i class="fa fa-key" aria-hidden="true"></i>
            <input type="password" [(ngModel)]="csrUser.csrPassword" placeholder="Password" name="loginPassword"formControlName="loginPassword">
            <div *ngIf="submitted && loginForm.controls.loginPassword.errors"  class="invalid-feedback2">
                <div *ngIf="loginForm.controls.loginPassword.errors.required" >Password is required</div>
            </div>
        </div>
        <div class="textbox">
            <i class="fa fa-user" aria-hidden="true"></i>
            <input type="email" [(ngModel)]="csrUser.insuredEmailID" placeholder="Insured Email" name="insUserEmail"formControlName="insUserEmail">
            <div *ngIf="submitted && loginForm.controls.insUserEmail.errors"  class="invalid-feedback2">
                <div *ngIf="loginForm.controls.insUserEmail.errors.required" >Insured Email ID is required</div>
            </div>
        </div>
    
        <button [disabled]="loading" class="buttonlogin">
            <span *ngIf="loading" ></span>
           Login
        </button>
       
        
        <div *ngIf="!loginSuccess" class="invalid-feedback2">
            <p>Invalid User Credentials.</p>
        </div>
        
    </div>
    
    </form>
</div>