import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../_models/user.model';
import { UserService } from '../_services/user.service';


@Component({
  selector: 'app-useractivation',
  templateUrl: './useractivation.component.html',
  styleUrls: ['./useractivation.component.css']
})
export class UseractivationComponent implements OnInit {
  activationCode: any;
  user: User = {
    companyID: 'MPI', subcompanyID: 'MPI', name: '', nameID: '', onlineAccessCode: '',
    policyNumber: '', password: '', userID: '', isValidEmail: true, isRegisteredEmail: false,
    deviceType: '', termsVersion: '', userUpdatedPassword: '', userUpdatedEmail: '', telephoneH: '', telephoneW: '', isValidAccessCode: true, isValidPolicyNumber: false, confirmPassword: '', activationCode: '', isValidUser: 0, isInActiveUser: false,
    isValidUserId: true, UpdatePwd: true, noofAttempts: 0, isVersionUpdated: false, versionDate: new Date(),
    emailValidationStatus: ''
  };
  constructor(private router: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    // subscribe to the parameters observable
    this.router.queryParams.subscribe(params => {
      console.log(params);
      this.user.activationCode=params['key'];
      console.log(this.activationCode);
      this.activateUser(this.user.activationCode);
      
    });
  }

  activateUser(activationCode: any){
            this.userService.ActivateUser(this.user)
      .subscribe(
        (response) => {
          this.user.isValidUser = response.isValidUser;
          console.log(response);
          console.log(this.user.isValidUser);       
        },
        error => {
          console.log(error);
        });
    }
  }

