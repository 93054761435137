import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler,HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "../_services/authentication.service";
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from "rxjs";

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    
    intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<Object>> {
        let authReq = req;

        this.authService.AutoLogoutTimer();

        const authToken = this.authService.GetToken();

        if(authToken != null)
        {
            authReq = this.addTokenHeader(req, authToken);
        }
       
        return next.handle(authReq).pipe(catchError(error => {
          
            if (error instanceof HttpErrorResponse  && error.status === 401) {
            
              return this.handle401Error(authReq, next);            
            }
           return throwError(error);
            }));
    }



    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        console.log('Handle 401 start');
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);
          const tokenCred = JSON.stringify({accessToken:this.authService.GetToken(), refreshToken: this.authService.GetRefreshToken() });
          //console.log('tok' + tokenCred);
          return this.authService.RefreshToken(tokenCred).pipe(
            switchMap((token: any) => {
              if(token != null){
                console.log('Handle 401 response:true');
                this.isRefreshing = false;
                this.refreshTokenSubject.next(token.accessToken);
                return next.handle(this.addTokenHeader(request, token.accessToken));
              }             
              console.log('Handle 401 response:false');
              this.isRefreshing = false;
              return throwError('Token expired');            
            }
            // ));
            ),
            catchError((err) => {
              console.log('Handle 401 error' + err.status);
              this.isRefreshing = false;
                if(err.status === 400){
                  alert('Your session expired. Please log in');
                  this.authService.Logout();                 
                }
                return throwError(err);       
              })
             
            );
    
        } 
        else {
            console.log('refreshtoken pipe');
       
          return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(jwt => {
              return next.handle(this.addTokenHeader(request, jwt));
            }));
        }
      }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        
        return request.clone({
            setHeaders: {
                Authorization: "Bearer " + token
            }
        });
      }
    
}