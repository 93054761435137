
<app-navigation-bar [isCsrUser]="isCsrUser" [name]="name" [onlineAccessCode]="onlineAccessCode"></app-navigation-bar> 
  <div>
   
        <label class="dropdowntag" for="activePolicyList">Select Account to View:</label>
          <select [(ngModel)]="selectedPolicy" (change)="changePolicy(0)" class="dp1">
            <option *ngFor="let policy of dropdownPolicies" [ngValue]="policy">{{policy.policyNo}} -
              {{policy.locationStreetAddress}}</option>
          </select>
         
            <span *ngIf="isPMRinPC">
              <label class="termDrpDown " for="activePolicyList">Select Policy Term:</label>
              <select id="drpPolicyTERM" [(ngModel)]="selectedPolicyTerm"   (change)="changePolicy(1)" class="dp2">
                <option  *ngFor="let policy of selectedPolicyData" [ngValue]="policy"  >
                  
                  {{policy?.policyEffDate|
                    date:'MM/dd/yyyy'}} - {{policy?.policyExpDate| date:'MM/dd/yyyy'}}
                </option>
              </select>
              </span>
         
  </div>
 
<a class="lossnoticeLink" (click)="RedirecttoLossNotice()"  tabindex="-1" aria-disabled="true">Report a Claim</a>
<br/>          
<nav class="navbar dropdowntag1">

  <div class="  collapse navbar-collapse mainnav flex-row " id="navbarTogglerDemo03">
    <ul class="navbar-nav  mr-auto  mainnav  col-sm-auto">
      <li class="nav-item active nvItem" [style.background-color]="isBillingTab ? '#47c806' : ''">
        <a class="nav-link" (click)='GetBillingTabData(selectedPolicyTerm)'>Billing <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item   nvItem" [style.background-color]="isPoliciesTab ? '#47c806' : ''">
        <a class="nav-link" (click)='GetPolicyTabData(selectedPolicyTerm)'>Policy</a>
      </li>
      <!-- <li class="nav-item   nvItem">
        <a class="nav-link " href="#" tabindex="-1" aria-disabled="true">Claims</a>
      </li> -->
      <li class="nav-item  nvItem" [style.background-color]="isInspectionTab ? '#47c806' : ''">
        <a class="nav-link " (click)="ShowInspection()" tabindex="-1" aria-disabled="true">Inspections</a>
      </li>
      <li class="nav-item   nvItem" [style.background-color]="isDocumentsTab ? '#47c806' :''">
        <a class="nav-link " (click)="ShowDocuments()" tabindex="-1" aria-disabled="true">Documents</a>
      </li>
      <!-- <li class="nav-item  nvItem">
        <a class="nav-link " href="#" tabindex="-1" aria-disabled="true">Help</a>
      </li> -->

    </ul>

  </div>

</nav>



<div class="left-panel-sec  ">

  <nav class="align-self-start  ">
    
    <div class=" collpase navbar-collapse  " id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto flex-column vertical-nav ">
        <div *ngIf="!noBillingAddress">
          <li class="nav-item-side">
            <a data-toggle="collapse" (click)="DisplayBilingAddress()"><span class="dropdown-toggle"></span>Billing
              Address</a>
          </li>
          <div *ngIf="isBillingAddress">
            <span class="leftPanelSecTxt" [innerHTML]="billingData">
            </span>
          </div>
        </div>
        <div *ngIf="!noLocationAddress">
          <li class="nav-item-side">
            <a data-toggle="collapse" (click)="DisplayLocationAddress()"><span class="dropdown-toggle"></span>Insured
              Location</a>
          </li>
          <div *ngIf="isLocationAddress">
            <span class="leftPanelSecTxt" [innerHTML]="locationAddressData">
            </span>
          </div>
        </div>
        <div *ngIf="!noAddInsured">
          <li class="nav-item-side">
            <a data-toggle="collapse" (click)="DisplayAdditionalInsured()"><span
                class="dropdown-toggle"></span>Additional Insured(s)</a>
          </li>
          <div *ngIf="isAddInsured">
            <div *ngFor="let addinsured of addInsuredList">
              <span class="leftPanelSecTxt">
                <span> {{addinsured.name}} <br /> </span>
                <span>{{addinsured.address1}} <br /></span>
                <span *ngIf="addinsured.address2!=null && addinsured.address2">{{addinsured.address2}} <br /></span>
                <span *ngIf="addinsured.address3!=null  && addinsured.address3">{{addinsured.address3}} <br /></span>
                <span>{{addinsured.city + ', ' + addinsured.state + ' ' + addinsured.zipCode}}</span>
              </span>
            </div>
            <span class="leftPanelSecTxt" *ngIf="noAddInsured">No Additional Insured Listed</span>
          </div>
        </div>
        <div *ngIf="!noMortgagees">
          <li class="nav-item-side">
            <a data-toggle="collapse" (click)="DisplayMortgagees()"><span
                class="dropdown-toggle"></span>Mortgagee(s)</a>
          </li>
          <div *ngIf="isMortgagees">
            <div *ngFor="let mortgagee of mortgageeList">
              <span class="leftPanelSecTxt">
                <span> {{mortgagee.name}} <br /></span>
                <span>{{mortgagee.address1}} <br /></span>
                <span *ngIf="mortgagee.address2!=null && mortgagee.address2">{{mortgagee.address2}} <br /> </span>
                <span *ngIf="mortgagee.address3!=null && mortgagee.address3">{{mortgagee.address3}} <br /></span>
                <span>{{mortgagee.city + ', ' + mortgagee.state + ' ' + mortgagee.zipCode}}</span>
              </span>
            </div>
            <span class="leftPanelSecTxt" *ngIf="noMortgagees">No mortgagees listed</span>

          </div>
        </div>
        <div *ngIf="!noClaims">
          <li class="nav-item-side">
            <a data-toggle="collapse" (click)="DisplayClaims()"><span class="dropdown-toggle"></span>Claims</a>
          </li>
          <div *ngIf="isClaims ">
            <div *ngFor="let claim of claimList">
              <span class="leftPanelSecTxt">
                Adjuster Name: {{claim.adjusterName}} <br /> Adjuster Phone: {{claim.adjusterPhone}} <br /> Claim
                Number: {{claim.claimNo}} <br /> Date of Loss: {{claim.lossDate | date:'MM/dd/yyyy'}} <br /> Status:
                {{claim.claimStatus}}
              </span>
            </div>
            <span class="leftPanelSecTxt" *ngIf="noClaims">No claims listed</span>

          </div>
        </div>
        <!-- <li class="nav-item-side">
          <a data-toggle="collapse"><span class="dropdown-toggle"></span>Inspection</a>
        </li> -->
        <div *ngIf="isOtherPoliciestab ">
          <li class="nav-item-side">
            <a data-toggle="collapse" (click)="DisplayOtherPolicies()"><span class="dropdown-toggle"></span>My Other Policies</a>
          </li>
          <div *ngIf="isPolicies">
            <div *ngFor="let policy of otherPolicies">
              <span class="leftPanelSecTxt">
                <span> {{policy.policyNo}} <br /></span>
              </span>
              <br/>
          </div>
          </div>
        </div>
      </ul>

    </div>
  </nav>
</div>
<!--  -->

<div class="container flex-container  ">
  <div class="row mt-0 " *ngIf="!isInspectionTab && !isDocumentsTab">
    <!-- Side Nav  -->

    <!-- Side Nav -->
    <div class="col-lg-6 ">
      <div class="card " style="width: 100%; ">

        <div class="card-body ">
          <span class="card-title mt-0 cHeader">{{policyDetails?.lineofBusiness}} Policy - {{policyDetails?.policyNo}}</span>
          <span class="spanofPolicy"><b>Policy Term <span style="color: darkblue;"> :{{policyDetails?.policyEffDate|
                date:'MM/dd/yyyy'}} -{{policyDetails?.policyExpDate| date:'MM/dd/yyyy'}}</span></b></span>
          <span class="spanofPolicy"><b>Insured Name <span style="color: darkblue;">
                :{{policyDetails?.name}}</span></b></span>
          <span class="spanofPolicy"><b>Insured Location:<span style="color: darkblue;"
                [innerHTML]="insuredLocation"></span></b></span>
          <!-- <span ng-show ="!pendingCancel"class="spanofPolicy"><b>Policy Status : Inforce<span style="color: darkblue;"></span></b></span> -->
          <span class="spanofPolicy"><b>Policy Status : <span
                [ngStyle]="pendingCancel==true?{'color':'red'} : {'color': 'darkblue'}">{{policyDetails?.policyStatus }}
                {{policyDetails?.cancellationEffDate| date:'MM/dd/yyyy'}}</span></b></span>
          <span class="spanofPolicy"><b>Policy Type : <span style="color: darkblue;">
                {{policyDetails?.policyType}}</span></b></span>
          <span class="spanofPolicy"><b>Full Term Policy Premium : <span
                style="color: darkblue;">{{policyDetails?.totalFullTermPrem|currency:"USD":"symbol"
                |minusToParenthesis}}</span> </b></span>
          <span class="spanofPolicy"><b>Total Premium Due : <span
                style="color: darkblue;">{{policyDetails?.totalPremiumDue|currency:"USD":"symbol" |minusToParenthesis}}</span></b></span>
          <span class="spanofPolicy" [ngStyle]="pendingCancel==true?{'color':'red'} : {'color': 'darkblue'}"><b>Minimum
              Payment : <span
                [ngStyle]="pendingCancel==true?{'color':'red'} : {'color': 'darkblue'}">{{policyDetails?.minimumPayment
                |currency:"USD":"symbol" |minusToParenthesis}}</span></b></span>
          <span class="spanofPolicy" [ngStyle]="pendingCancel==true?{'color':'red'} : {'color': 'darkblue'}"><b>Payment
              Due Date : <span [ngStyle]="pendingCancel==true?{'color':'red'} : {'color': 'darkblue'}">{{paymentDueDate|
                date:'MM/dd/yyyy'}}</span></b></span>
          <div *ngIf="pendingCancel " class="alert alert-danger" role="alert">
            You currently have a premium amount that is over due.
            Pending Cancellation Effective with {{policyDetails.cancellationEffDate| date:'MM/dd/yyyy'}}.
          </div>

          <button *ngIf="isBillingTab && !isPoliciesTab" type="button" class="btn btn-success "
            (click)="RedirecttoMakePayment()">Pay My Bill</button>
          <button *ngIf="isBillingTab && !isPoliciesTab" type="button" class="btn btn-info "
            (click)="GetInvoiceDocuments(selectedPolicy)">View My Bill</button>
          <button *ngIf="!isBillingTab && isPoliciesTab" type="button" class="btn btn-primary viewPolicyButton"
            (click)="GetPolicyDocuments(selectedPolicy)">View My Policy</button>
          <div *ngIf="!documentAvailable" class="invalid-feedback2">
            <p>No Document available</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 ">
      <div class="card " style="width: 100%; text-align: left;">

        <div class="card-body">
          <span class="headAgent card-title cHeader">Agent Contact Information</span>
          <div>
            <span class="spanofPolicy"><b>{{agentInfo?.name}}</b></span>
            <span class="spanofPolicy"><b>{{agentInfo?.address1}}</b></span>
            <span class="spanofPolicy" *ngIf="agentInfo?.address2!=null"><b>{{agentInfo?.address2}}</b></span>
            <span class="spanofPolicy" *ngIf="agentInfo?.address3!=null"><b>{{agentInfo?.address3}}</b></span>
            <span class="spanofPolicy"><b>{{agentInfo?.city}}, {{agentInfo?.state}} {{agentInfo?.zipCode}}</b></span>
            <span class="spanofPolicy"><b>{{agentInfo?.telephoneW}} {{agentInfo?.telephoneH}}</b></span>
            <span class="spanofPolicy"><b>{{agentInfo?.emailAddress}}</b></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-inspection  *ngIf="isInspectionTab" [policyNo]="policyNo" [pMRSequence]="pMRSequence"></app-inspection>
  <app-documents  *ngIf="isDocumentsTab" [policyNo]="policyNo"  ></app-documents>
</div>
<!-- payment and outstanding invoice section -->

<div class="container " *ngIf="!isInspectionTab && !isDocumentsTab">
  <div *ngIf="isBillingTab && !isPoliciesTab ">
    <span class="outstand cHeader">Outstanding Invoices</span>
    <div class="table-responsive">
      <table class="table table-hover ">
        <thead>
          <tr>
            <th>Invoice DueDate</th>
            <th>Status</th>
            <th>Billed Premium</th>
            <th>
              Billed Service Fee
            </th>
            <th>
              Paid Premium
            </th>
            <th>
              Paid Service Fee
            </th>
            <th>
              Billed Total
            </th>
            <th>
              Paid Total
            </th>
            <th>
              Total Due
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of invoiceData">
            <td>{{invoice.dueDate| date:'MM/dd/yyyy'}}</td>
            <td>{{invoice.status}}</td>
            <td>{{invoice.billedPremium |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{invoice.billedServiceFee |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{invoice.paidPremium |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{invoice.paidServiceFee |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{invoice.billedTotal |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{invoice.paidTotal |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{invoice.totalDue |currency:"USD":"symbol" |minusToParenthesis}}</td>
          </tr>


        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="isBillingTab && !isPoliciesTab">
    <span class="paymenthis">Payment History</span>
    <div class="table-responsive">
      <table class="table table-hover ">
        <thead>
          <tr>
            <th>Date Received</th>
            <th>Payment Amount</th>
            <th>Amount Applied to premium</th>
            <th>
              Check Number
            </th>
            <th>
              Payment Type
            </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of paymentData">
            <td>{{payment.enterDate| date:'MM/dd/yyyy' }}</td>
            <td>{{payment.paymentAmount |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{payment.checkAmount |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{payment.checkNo}}</td>
            <td>{{payment.paymentType}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!isBillingTab && isPoliciesTab">
    <span class="outstand">Coverages</span>
    <div class="table-responsive">
      <table class="table table-hover ">
        <thead>
          <tr>
            <th>Description</th>
            <th>Limit</th>
            <th>Deductible</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let coverage of coverageList">
            <td>{{coverage.coverageDesc}}</td>
            <td>{{coverage.coverageAmount1 |currency:"USD":"symbol" |minusToParenthesis}}</td>
            <td>{{coverage.deductibleAmount1 |currency:"USD":"symbol" |minusToParenthesis}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!isBillingTab && isPoliciesTab">
    <div *ngIf="isDeductible">
     <!-- Modified as a part of squish#43 Correct display of WindHail/NamedStorm/Hurricane deductible -->
    <span class="paymenthis" style="margin-bottom: 2%;">Wind & Hail \ NamedStorm \ Hurricane Deductible <span
        style="margin-left: 29%;">{{whDeductible|currency:"USD":"symbol" |minusToParenthesis }} </span></span>
  </div>
  <div *ngIf="!isDeductible">
    <!-- Modified as a part of squish#43 Correct display of WindHail/NamedStorm/Hurricane deductible -->
   <span class="paymenthis" style="margin-bottom: 2%;">Wind & Hail \ NamedStorm \ Hurricane Deductible <span
       style="margin-left: 29%;">See above Deduct</span></span>
 </div>
  <div *ngIf="!isBillingTab && isPoliciesTab">
    <span class="outstand">Endorsements</span>
    <div class="table-responsive">
      <table class="table table-hover ">
        <thead>
          <tr>
            <th>Form</th>
            <th>Description</th>
            <th>Annual Premium</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let endorsement of endorsementData">
            <td>{{endorsement.formID}}</td>
            <td>{{endorsement.formDescription}}</td>
            <td>{{endorsement.annPrem |currency:"USD":"symbol" |minusToParenthesis}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


