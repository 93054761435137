import { Component, OnInit,Input } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
    selector: 'app-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.css']
  })
  export class NavigationBarComponent implements OnInit {
    @Input() isCsrUser: boolean;
    @Input() name: string;
    @Input() onlineAccessCode: string;
    dateToday:number = Date.now();
    constructor( private authService: AuthenticationService) { 
    
    }
    ngOnInit(): void {
     
    }

    LogOut(){
      this.authService.Logout(this.isCsrUser);
    } 
  }