import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from '../_services/authentication.service';
import {CsrService} from "../_services/csr.service";
import { FormBuilder, FormControl,FormGroup,  Validators,  } from "@angular/forms";
import { CsrUser } from '../_models/csr.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-csrlogin',
  templateUrl: './csrlogin.component.html',
  styleUrls: ['./csrlogin.component.css']
})



export class CsrLoginComponent implements OnInit {
  public loginForm: any; 
  loading = false;
  submitted = false;
  public csrUser: CsrUser ={companyID:'',subcompanyID:'',  csrUserID:'',csrPassword:'',insuredEmailID:'',
  nameID:'',name:'',noofAttempts:0,onlineAccessCode:'',insuredUpdatedEmailID:''};
  loginSuccess: boolean =true;

constructor(private formBuilder: FormBuilder, private router: Router,private csrService : CsrService,private actrouter : ActivatedRoute) {}

ngOnInit() {
this.loginForm = new FormGroup({
LoginEmail: new FormControl("", [Validators.required]),
loginPassword: new FormControl("", [Validators.required]),
insUserEmail: new FormControl("", [Validators.required]),
});
this.csrUser.companyID = environment.companyCode;
this.csrUser.subcompanyID = environment.companyCode;


}

onSubmit() {
  this.submitted = true;
   
    if (this.loginForm.invalid) {
      console.log(this.loginForm.invalid);
      return;
  }
  this.csrService.Login(this.csrUser)
  .subscribe(
    response => {
      if(response){
        if(response==true){
         
        }
        else if(response>=5){
          //this.accountLocked=true;
        }
        else{
          this.submitted=true;
        this.loginSuccess=true;
        this.router.navigate(['/dashboard']);
        }
      }
         else{
          this.loginSuccess=false;
         }       
    },
    error => {
      console.log(error);
      this.loginSuccess=false;
    });
}


}