import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { User } from '../_models/user.model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
  })
export class AuthenticationService{
    private readonly JWT_TOKEN = 'token';
    private readonly REFRESH_TOKEN = 'refreshToken';
    helper = new JwtHelperService();
    interval:any;
    
  constructor(private http: HttpClient,private router: Router) {}
     
  Login(user: User): Observable<any> {  
    console.log(user);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };  
    return this.http.post<User>(`${environment.apiUrl}user/Login/`,  
      user, httpOptions).pipe(
          map((response: any) => {
          if (response!=null && response.token!=null){
                       this.StoreToken(response); 
                       this.AutoLogoutTimer();           
         }  
         return response;  
                 
     })
     );  
  }
  IsLoggedIn(): boolean {
    const token = localStorage.getItem(this.JWT_TOKEN);
    return !this.helper.isTokenExpired(token);
  }
   
   GetToken() {
        return localStorage.getItem(this.JWT_TOKEN);
      }

  GetRefreshToken(){
      return localStorage.getItem(this.REFRESH_TOKEN);
    }

  Logout(isCsr:boolean=false) {
        this.RemoveTokens();   

        //stop auto logout timer on logout
        if(this.interval)
        {
        clearTimeout(this.interval);      
        }  

          if(isCsr)
          {
            this.router.navigate(['csrlogin']);
          }
          else{
            this.router.navigate(['login']);
          }
   }

  async GetRefreshTokenAsync(token:any):Promise<boolean>{
       const response = await  this.http.post(environment.apiUrl+'token/refreshtoken/', token, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        }),
        observe: 'response'
      }).toPromise();
        const newToken = (<any>response).body.accessToken;
        const newRefreshToken = (<any>response).body.refreshToken;
        localStorage.setItem(this.JWT_TOKEN, newToken);        
        localStorage.setItem(this.REFRESH_TOKEN, newRefreshToken);
        return true;
      }
     

    RefreshToken(param:any) {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
        return this.http.post(environment.apiUrl +  'token/refreshtoken/',param, 
          httpOptions
        ).pipe(tap((response: any) => {
          console.log('response' + response);
          if (response!=null && response.accessToken!=null){
            localStorage.setItem(this.JWT_TOKEN, response.accessToken);
            localStorage.setItem(this.REFRESH_TOKEN, response.refreshToken);
         } 
        // .pipe(tap((response:any) => {
        //   //this.storeJwtToken(tokens.jwt);
        //   console.log('response' + response);
        //   localStorage.setItem("token", response.accessToken);
        // localStorage.setItem("refreshToken", response.refreshToken);
        // }));
       return response;
      }));
    }

    StoreToken(tokens:any){
      localStorage.setItem(this.JWT_TOKEN, tokens.token);
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }

   RemoveTokens() {
      localStorage.removeItem(this.JWT_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
    }

    AutoLogoutTimer(){
     
      if(this.interval)
      {
        console.log('clear Timer');
       clearInterval(this.interval);
      }
    
      this.interval =  setInterval(() => {
        
       if (!this.IsLoggedIn()) {  
        console.log('User Session Expired');      
        //window.alert("Your session expired. Please log in");
        this.Logout();
      }
      },960000); //Timer to verify access token on 16 th minute.
    
    }
  
}