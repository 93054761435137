import { Component, OnInit ,ViewChild, TemplateRef} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from '../_services/authentication.service';
import {UserService} from "../_services/user.service";




import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  } from "@angular/forms";
import { User } from '../_models/user.model';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})



export class LoginComponent implements OnInit {
  public loginForm: any; 
  loading = false;
  submitted = false;
  user: User = {
    companyID: '', subcompanyID: '', name: '', nameID: '', onlineAccessCode: '',
    policyNumber: '', password: '', userID: '', isValidEmail: true, isRegisteredEmail: false,
    deviceType: '', termsVersion: '', userUpdatedPassword: '', userUpdatedEmail: '', telephoneH: '', telephoneW: '', isValidAccessCode: true, isValidPolicyNumber: false, confirmPassword: '', activationCode: '', isValidUser: 0, isInActiveUser: false, isValidUserId: true,
    UpdatePwd: true, noofAttempts: 0, isVersionUpdated: false, versionDate: new Date(),
    emailValidationStatus: ''
  };
  loginSuccess: boolean =true;
  isInActiveUser:boolean=false;
  reactivationSuccess:boolean=false;
  reactivationFailed: boolean=false;
  isActive: any;
  accountLocked: boolean=false;
  blob: Blob;
  termsVersion: string;
  iframeTerms: SafeResourceUrl = undefined; 
  deviceInfo :null;
  termsFailed : boolean= false;
  versionUpdateDate : Date;
  CompanyName:string;
  emailValidationFailed: boolean = false;

  @ViewChild("termsupdated") modalContent: TemplateRef<any>;
  @ViewChild("errorMsg") errorModalContent: TemplateRef<any>;
    


constructor(private formBuilder: FormBuilder, private router: Router,private authService : AuthenticationService,
            private userService : UserService,private actrouter : ActivatedRoute,private sanitizer: DomSanitizer,private modalService: NgbModal) {}

ngOnInit() {
this.loginForm = new FormGroup({
LoginEmail: new FormControl("", [Validators.required]),
loginPassword: new FormControl("", [Validators.required]),
});
this.user.companyID = environment.companyCode;
this.user.subcompanyID = environment.companyCode;
if(environment.companyCode=="MPI")
{
  this.CompanyName="MPIUA"
}
else
{
  this.CompanyName="RIJRA"
}
this.actrouter.queryParams.subscribe(params => {
  console.log(params);
  this.isActive=params['pwdupdated'];
});
}

onSubmit() {
  this.submitted = true;
    const data = {
      password : this.user.password,
      email : this.user.userID
    };
    if (this.loginForm.invalid) {
      console.log(this.loginForm.invalid);
      return;
  }
  this.authService.Login(this.user)
  .subscribe(
    response => {
      if(response){
        if(response.isInActiveUser==true){
          this.isInActiveUser=true;  
        }
        else if(response.noofAttempts>=5){
          this.accountLocked=true;
          this.loginSuccess=true;
        }
        else{
          if(response.isVersionUpdated==true)
          {
            this.versionUpdateDate=response.versionDate;
            this.GetTermsDocument();
            this.modalService.open(this.modalContent);
          }
          else{
            this.submitted=true;
            this.loginSuccess=true;
            this.router.navigate(['/dashboard']);
          }
        }
      }
         else{
          this.loginSuccess=false;
         }       
    },
    error => {
      console.log(error);
      this.loginSuccess=false;
    });
}


ResendActivationLink(){

  this.userService.ResendActivationLink(this.user)
  .subscribe(
    (response) => {

      if(response){
        if ("mailService_Success" in response && "emailValidation_Success" in response)
        {
       this.reactivationSuccess=true;
        }
        else{
          this.reactivationFailed=true;
    this.emailValidationFailed =  !("emailValidation_Success" in response);
        }
      }
         else{
          this.reactivationFailed=true;
         }       
    },
    error => {
      console.log(error);
    });
}

openTermsAndConditions(content: any) {
  this.modalService.open(content, { size: 'lg' });
}

GetTermsDocument(){
      
  const httpparams = new HttpParams()
  .set('companyId', environment.companyCode)
  .set('subCompanyId', environment.companyCode);
  this.userService.GetTermsDocuments(httpparams)
 .subscribe( 
   response => {
    console.log(response.headers); 
    const contentDispositionHeaderResult = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
    this.termsVersion = contentDispositionHeaderResult.replace(/"/g, '');
 
    this.blob = new Blob([response.body], {type: 'application/pdf'});
    let blobUrl: string = window.URL.createObjectURL(this.blob);
    this.iframeTerms = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl +"#toolbar=0");
   
       
 },
  error => {
   console.log(error);
 });
}

UpdateTermsVersion(){
  this.user.termsVersion=this.termsVersion ;
  this.userService.UpdateTermsVersion(this.user)
.subscribe(
  response => {
    if(response){
      this.submitted=true;
      this.loginSuccess=true;
      this.router.navigate(['/dashboard']);
 
    }
       else{
     
        this.modalService.open(this.errorModalContent);
       }       
  },
  error => {
    console.log(error);
    this.modalService.open(this.errorModalContent);
 
  });
}

}
